import { Component, OnInit } from '@angular/core';
import { OverviewService } from './overview.service';
import { ViewEditService } from '../viewedit/viewedit.service';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import { AuthService } from '../auth/auth.service';
import { UserDetailsService } from '../login/user-details.service';
import { Router } from "@angular/router";

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html'
})
  
export class OverviewComponent implements OnInit {

  messages = [];
  resultsLoading = true;
  selectedMessage = { };
  currentRun;
  nextRun;
  createdSchedules;
  notCreatedSchedules;
  
  constructor(private overviewService: OverviewService,
              private viewEditService: ViewEditService,
              private createScheduleService: CreateScheduleService,
              private router: Router,
              private authService: AuthService,
              private userDetailsService: UserDetailsService) {
   }

  ngOnInit() {
    this.getMessages();
    let self = this;
    this.populateRuns();
  }

   navToCreatedSchedule(run, beamline) {
     this.router.navigate(["viewedit", { run: run, beamline: beamline } ]);
   }
  
   navToNotCreatedSchedule(run, beamline) {
     this.router.navigate(["createschedule", { run: run, beamline: beamline } ]);
   }
  
   getMessages() {
      this.overviewService.getActiveAdminMessages().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.messages = json;
        this.resultsLoading = false;
        this.stripTimeFromDates();
        this.selectedMessage = this.messages[0];
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting admin messages: \n" + error.error);
        }
        console.log(error.error);
      }
    });
   }
  
    stripTimeFromDates() {
      this.messages.forEach( m => { m.Posted_Date = m.Posted_Date.split("T")[0] } );
    }
  
    populateSchedules(existingSchedules) {
      let self = this;
      let existing = existingSchedules ? "YES" : "NO";
      
      this.createScheduleService.getBeamlinesForSuperUser(this.authService.getBadgeNo(), this.currentRun, existing).subscribe({
        next: data => {
          let json = JSON.parse(data['body']);
          if (existingSchedules) {
            this.createdSchedules = json;
          } else {
            this.notCreatedSchedules = json;
          }
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting beamlines for super user: \n" + error.error);
          }
          console.log(error.error);
        }
      });
    }
  
    navToRun(run) {
      this.router.navigate(["viewedit", { run: run, useCurrentMonth: false, scheduleTypeBeamline: true } ]);
    }
  
    populateRuns() {
      let self = this;
      this.viewEditService.getRuns().subscribe({
        next: data => {
          let json = JSON.parse(data['body']);
          this.setCurrentAndNextRuns(json);
          if (this.authService.getUser() && this.authService.getUser().badgeNo) {
            this.populateSchedules(false);
            this.populateSchedules(true);
          } else {
            let self = this;
            this.userDetailsService.userInfoLoaded.subscribe(function(user){ 
              self.populateSchedules(false);
              self.populateSchedules(true);
            });
          }

        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting runs: \n" + error.error);
          }
          console.log(error.error);
        }
      });
    }
  
    setCurrentAndNextRuns(runs) {
      let today = new Date();
    
      runs.forEach( r => { 
        let start = new Date(r.RunStart.split("T")[0]);
        let end = new Date(r.RunEnd.split("T")[0]);
        if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
          this.currentRun = r.RunName;
        } else {
          if (this.currentRun && !this.nextRun) {
            this.nextRun = r.RunName;
          }
      } 
    });
  }
}
