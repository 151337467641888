import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map } from "rxjs/operators";
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserDetailsService {

  userInfoLoaded = new BehaviorSubject(null);

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }


  public getUserDetails(username: string) {
    let getUserUrl = this.authService.getUrlRoot() + "/data/getUserDetails/" + username;
    this.http.get(getUserUrl).pipe(
      map(
        userData => {
          let ud = JSON.parse(userData['body'])[0];
          let user = new User();
          user.userName = username;
          user.badgeNo = ud['Badge_No'];
          user.fullName = ud['Name'];
          console.log(user.fullName);
          user.email = ud['Email'];
          user.apsGroup = ud['Group'];
          this.getPermissions(user);
          this.getSessionId(user.badgeNo);
        }
        , catchError(this.handleError)
      )).subscribe();
  }

  public getSessionId(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getSessionId/'
      + badgeNo;

      this.http.post(url, navigator.userAgent).subscribe({
        next: data => {
          this.authService.setSessionId(data['body']);
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting session ID: \n" + error.error);
          }
          console.log(error);
        }
      });
  };

  getBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }

  getBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  handleError(error: HttpErrorResponse) {
    console.log('Error on getting user details/n' + error.error);
    return Observable.throw(error);
  }


  getPermissions(user: User) {
    let temp = [];
    this.getPermissionsSummary(user.badgeNo).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        user.permissions = json;
        this.authService.setUser(user);
        this.userInfoLoaded.next(user);
      },
      error: error => {
        console.log(error);
      }
    });
  }

  getPermissionsSummary(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getPermissionsSummary/'
      + badgeNo;
    return this.http.get(url);
  }
    
}