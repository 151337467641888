import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
  
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  msgs: any[];
  resultsLoading = false;

  constructor(private authService: AuthService,
        private userDetailsService: UserDetailsService,
        private router: Router) { }
 
  ngOnInit() {
  }

  login() {
    this.resultsLoading = true;
    this.authService.login(this.username, this.password).subscribe(
      authenticated => {
        this.resultsLoading = false;
        console.log(authenticated);
        if (authenticated) {
          this.userDetailsService.getUserDetails(this.username);
        }
        // send to different route if they're deep linking
        let url = this.authService.desiredRoute;
        if (!url) {
          url = "/overview";
        }
        this.authService.desiredRoute = null;
        this.router.navigateByUrl(url); 
      },
      err => {
          this.msgs = [{ severity: "error", detail: "Login failed" }];
      }
    );
  }
}
