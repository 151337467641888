import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class SettingsTemplatesService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  editMessageTemplate(badgeNo, template) {
    let url = this.authService.getUrlRoot() + '/data/editMessageTemplate/' + badgeNo;
    return this.unsecureHttp.post(url,template);
  }

  copyMessageTemplate(badgeNo, domain, template) {
    let url = this.authService.getUrlRoot() 
      + '/data/CopyMessageTemplate/' 
      + badgeNo + "/" + domain;
    return this.unsecureHttp.post(url,template);
  }

  getFullMessageDetails(badgeNo, domain, template) {
    console.log(domain);
    let url = this.authService.getUrlRoot() + '/data/getFullMessageDetails/' 
      + badgeNo + "/" + domain.replace("/", "~")  + "/" +  template.replace("/", "~");
    return this.unsecureHttp.get(url);
  }
  
 
  getGroupScopeRoles() {
    let url = this.authService.getUrlRoot() + '/data/getGroupScopeRoles/'; 
    return this.unsecureHttp.get(url);
  }

  getMessageTemplatesToCopy(domain, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getMessageTemplatesToCopy/' + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }

  getMessageTemplatesForDomain(domain, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getMessageTemplatesForDomain/' + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }
  
  getMessageDomains(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getMessageDomains/' + badgeNo;
    return this.unsecureHttp.get(url);
  }
    
  addAuthorizationGroup(group, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/addAuthorizationGroup/' + badgeNo;
    return this.unsecureHttp.post(url, group);
  }
  
  addAuthorizationGroupMember(member, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/addAuthorizationGroupMember/' + badgeNo;
    return this.unsecureHttp.post(url, member);
  }
  
  deleteAuthorizationGroup(domain, group, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/deleteAuthorizationGroup/' 
      + badgeNo + "/" + domain + "/" + group;
    return this.unsecureHttp.get(url);
  }
  
  deleteAuthorizationGroupMember(domain, group, memberBadgeNo, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/deleteAuthorizationGroupMember/' 
      + badgeNo + "/" + domain + "/" + group + "/" + memberBadgeNo;
    return this.unsecureHttp.get(url);
  }
  
} 