import { Component, OnInit } from '@angular/core';
import { PreferencesService } from './preferences.service';
import { ViewEditService } from '../viewedit/viewedit.service';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import { AuthService } from '../auth/auth.service';
import { Router } from "@angular/router";
import { CalendarService } from '../calendar/calendar.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html'
})

export class PreferencesComponent implements OnInit {

  domains = [];
  selectedDomain;
  resultsLoading = true;
  optInPreference = false;
  domainPrefs = {};
  msgs: Message[];
  emailDays;
  shiftHours;

  constructor(private preferencesService: PreferencesService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private calendarService: CalendarService) {
  }

  ngOnInit() {
    this.getDomains();
    this.getOptInPreference();
    this.emailDays = { ESAFReminder: 0 };
  }

  getOptInPreference() {
    this.preferencesService.getOptInPreference(this.authService.getBadgeNo()).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.optInPreference = json[0].RunChangeOptIn;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting opt in pref: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }

  getDomains() {
    this.preferencesService.getPreferencesDomains(this.authService.getBadgeNo()).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach( j => { this.domains.push( { domain: j })});
        this.resultsLoading = false;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting domains: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }

  getDomainPrefs() {
    this.preferencesService.getDomainPreferences(this.authService.getBadgeNo(), this.selectedDomain.domain.replace("/", "slash")).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          console.log(json);
          this.domainPrefs = json[0];
          this.resultsLoading = false;
        } else {
          this.domainPrefs = {};
          console.log("No preferences found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting domain preferences: \n" + error.error);
        }
        console.log(error.error);      
      }
    });

    this.getEmailNotificationDays();
    this.getDefaultShiftHours();
  }

    getEmailNotificationDays() {
      this.preferencesService.getEmailNotificationDays(this.authService.getBadgeNo(), this.selectedDomain.domain.replace("/", "slash")).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);  
          console.log(json);
          this.emailDays = json;
          this.resultsLoading = false;
        } else {
          this.domainPrefs = {};
          console.log("No email notification days setting found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting email notification days: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  getDefaultShiftHours() {
      this.preferencesService.getDefaultShiftHours(this.authService.getBadgeNo(), this.selectedDomain.domain.replace("/", "slash")).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);  
          console.log(json);
          this.shiftHours = json;
          this.resultsLoading = false;
        } else {
          this.domainPrefs = {};
          console.log("No shift hours setting found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting default shift hours: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }



  saveOptInPref() {
    this.preferencesService.editRunOptInPreference(this.authService.getBadgeNo(), this.optInPreference).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Success', detail: "Saved successfully" });
      },
      error: error => {
        this.msgs.push({ severity: "error", summary: 'Error', detail: "Error on saving.\nSee JavaScript console." });

        console.log(error);
      }
    });
  }

    updateESAFEmailNotificationDays() {
      console.log(this.selectedDomain);
      console.log(this.emailDays);
      this.preferencesService.updateESAFEmailNotificationDays(this.authService.getBadgeNo(), 
      this.selectedDomain.domain.replace("/", "slash"),
      this.emailDays.ESAFReminder).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Success', detail: "Saved successfully" });
      },
      error: error => {
        this.msgs.push({ severity: "error", summary: 'Error', detail: "Error on saving email notification days.\nSee JavaScript console." });

        console.log(error);
      }
    });
  }


  updateShiftHours() {
      console.log(this.selectedDomain);
      console.log(this.shiftHours);
      this.preferencesService.updateDefaultShiftHours(this.authService.getBadgeNo(), 
      this.selectedDomain.domain.replace("/", "slash"),
      this.shiftHours).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Success', detail: "Saved successfully" });
      },
      error: error => {
        this.msgs.push({ severity: "error", summary: 'Error', detail: "Error on saving default shift hours.\nSee JavaScript console." });

        console.log(error);
      }
    });
  }

  saveDomainPrefs() {
    if (!this.domainPrefs) {
      return;
    }

    this.preferencesService.editDomainPreference(this.authService.getBadgeNo(), this.domainPrefs).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Success', detail: "Saved successfully" });
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on saving domain preferences: \n" + error.error);
        }
        console.log(error.error);
      }
    });

    this.updateESAFEmailNotificationDays();
    this.updateShiftHours();
  }

}
