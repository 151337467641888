import { Component, OnInit } from '@angular/core';
import { Constants } from '../shared/constants';
import { ReportingRunScheduleService } from './reporting-run-schedule.service';
import { SelectItem } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import * as fileSaver from 'file-saver';

interface Book {
  name: string;
  author: string;
}

@Component({
  selector: 'reporting-run-schedule',
  templateUrl: './reporting-run-schedule.component.html',
})
export class ReportingRunScheduleComponent implements OnInit {

  resultsLoading = true;
  runsSingleRun = [];
  runSingleRun;

  schedulesSingleRun = [];
  scheduleSingleRun;

  runsLongRange = [];
  runLongRange;

  schedulesLongRange = [];
  scheduleLongRange;

  longRangeSchedulesForReport = [];
  selectedLongRangeSchedule;

  constructor(
    private viewEditService: ReportingRunScheduleService,
    private authService: AuthService,
    private reportingService: ReportingRunScheduleService) {
  }

  addScheduleToList() {
    if (!this.scheduleLongRange) {
      return;
    }
    
    let index = this.longRangeSchedulesForReport.findIndex( i => i.value == this.scheduleLongRange );

    if (index < 0) {
      console.log(this.scheduleLongRange);
      let slr = { value: this.scheduleLongRange, label: this.scheduleLongRange };
      this.longRangeSchedulesForReport.push(slr);
      this.longRangeSchedulesForReport = JSON.parse(JSON.stringify(this.longRangeSchedulesForReport));
    }
  }

  downloadLongRangeExcel() {
    console.log("getting long range report");
    this.resultsLoading = true;
    let schedules = this.longRangeSchedulesForReport.map((i: any) =>
              ({ Run_Name: i.value.split(" - ")[0], Schedule_Name: i.value.split(" - ")[1] })
            );
            console.log(schedules);
    this.reportingService.downloadLongRangeExcel(
        schedules).subscribe({
      next: data => {
        console.log(data);
        let blob: any = new Blob([data]);
        this.resultsLoading = false;
        fileSaver.saveAs(blob, "APS_Long_Range_Operations_Schedule_" 
          + this.runLongRange + ".xlsx");
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on downloading report: \n" + error.error);
        }
        console.log(error);
      }
    });
  }

  removeScheduleFromList() {
    let index = this.longRangeSchedulesForReport.findIndex( i => i.value == this.selectedLongRangeSchedule );
    this.longRangeSchedulesForReport.splice(index, 1);
    this.longRangeSchedulesForReport = JSON.parse(JSON.stringify(this.longRangeSchedulesForReport));
  }

  populateRunsSingleRun() {
    let self = this;
    this.runsSingleRun = [];
    this.viewEditService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runsSingleRun.push({ value: r.RunName, label: r.RunName, run: r });
        });
        this.resultsLoading = false;
        let currentRun = this.getCurrentRun(this.runsSingleRun);
        if (currentRun) {
          self.runSingleRun = currentRun.RunName;
          self.populateSchedulesSingleRun();
        }
      },
      error: error => {
        if (error.status != 401) {        
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  populateRunsLongRange() {
    let self = this;
    this.runsLongRange = [];
    this.viewEditService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runsLongRange.push({ value: r.RunName, label: r.RunName, run: r });
        });
        this.resultsLoading = false;
        self.runLongRange = self.runsLongRange[0].value;
        let currentRun = this.getCurrentRun(this.runsLongRange);
        if (currentRun) {
          self.runLongRange = currentRun.RunName;
          self.populateSchedulesLongRange();
        }
      },
      error: error => {
        if (error.status != 401) {        
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun(runs) {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    return currentRun;
  }

  populateSchedulesSingleRun() {

    if (!this.runSingleRun) {
      return;
    }

    let currentBeamline = this.scheduleSingleRun;
    this.scheduleSingleRun = null;;

    let self = this;
    this.schedulesSingleRun = [];
    this.viewEditService.getBeamlinesForRun(this.runSingleRun, "Run").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        if (json.length > 0) {
          let options;

          if (json[0].Beamline) {
            options = json.map((i: any) =>
              ({ label: i.Beamline, value: i.Beamline })
            );
          } else {
            options = json.map((i: any) =>
              ({ label: i.ScheduleName, value: i.ScheduleName })
            );

          }
          self.schedulesSingleRun = options;
          self.scheduleSingleRun = options[0].value;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  
  populateSchedulesLongRange() {

    let currentBeamline = this.scheduleLongRange;
    this.scheduleLongRange = null;;
    let self = this;
    this.viewEditService.getBeamlinesForRun(this.runLongRange, "Run").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        if (json.length > 0) {
          let options;

          if (json[0].Beamline) {
            options = json.map((i: any) =>
              ({ label: i.Beamline, value: i.Beamline })
            );
          } else {
            options = json.map((i: any) =>
              ({ label: this.runLongRange + " - " + i.ScheduleName, value: this.runLongRange + " - " + i.ScheduleName })
            );

          }
          self.schedulesLongRange = options;
          self.scheduleLongRange = options[0].value;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  downloadCSV() {
    this.reportingService.downloadFile(this.authService.getBadgeNo(), this.runSingleRun, this.scheduleSingleRun).subscribe(
      response => {
        let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
        fileSaver.saveAs(blob, "single-run-" + this.runSingleRun + ".csv");
      }), error => {
        if (error.status != 401) {
          alert("Error on downloading report: \n" + error.error);
        }
        console.log(error);
      }
      ,
      () => console.info('File downloaded successfully');
  }

  ngOnInit() {

    this.populateRunsSingleRun();
    this.populateRunsLongRange();
  }

}
