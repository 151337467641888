import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class CreateScheduleService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  saveRun(run, badgeNumber) {
    let url = this.authService.getUrlRoot() + '/data/addRun/' + badgeNumber;
    return this.unsecureHttp.post(url, run);  
  }
  
   
  getRunsForYear(year: number) {
    let nextYear = +year + 1;
    let url = this.authService.getUrlRoot() + "/data/getRunsByStartEndDates/1-1-" + year + "/2-1-" + nextYear;
    
    return this.unsecureHttp.get(url);
  }
  
  checkUserRunSchedulePrivilege(badgeNo) {
    let url = this.authService.getUrlRoot() + "/data/checkUserRunSchedulePrivilege/" + badgeNo;
    return this.unsecureHttp.get(url);
  }
     
  getBeamlinesForSuperUser(badgeNo, run, alreadyScheduled) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForSuperUser/" + badgeNo 
      + "/" 
      + run
      + "/"
      + alreadyScheduled;
    return this.unsecureHttp.get(url);
  }
  
  saveSchedule(schedule, badgeNumber) {
    let url = this.authService.getUrlRoot() + '/data/addSchedule/' + badgeNumber;
    return this.unsecureHttp.post(url, schedule);  
  }
} 