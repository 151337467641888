import { Component, OnInit } from '@angular/core';
import { AdminService } from './admin.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {


  messages = [];
  resultsLoading = true;
  selectedMessage = { };
  addingNew = false;
  newMessage = "";
  newMessageSubject = "";
  activeMsgOpts = [];
  activeMsgChoice;
  
  constructor(private adminService: AdminService,
            private authService: AuthService) {
   }

  addNew() {
    this.addingNew = true;
  }
  
  cancelNew() {
    this.addingNew = false;
  }
  
  getTimestamp() {
    let now = new Date();
    return now.getFullYear() + "-"
      + (now.getMonth() + 1) + "-"
      + now.getDate(); // + "T" 
      //+ now.getHours() + ":"
      //+ now.getMinutes() + ":"
      //+ now.getSeconds();
  }
  
  delete() {
     this.adminService.deleteMessage(this.selectedMessage['Message_ID'], this.authService.getUser().badgeNo).subscribe({
      next: data => {
        console.log("Delete message response: " + data['body']);
        this.getMessages();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting session ID: \n" + error.error);
        }
        console.log("Delete message error:");
        console.log(error);
      }
    });
  }
  
  update() {
     this.adminService.updateMessage(this.selectedMessage, this.authService.getUser().badgeNo).subscribe({
      next: data => {
        console.log("Update message response: " + data['body']);
        this.getMessages();
      },
      error: error => {
        console.log("Update message error:");
        console.log(error);
      }
    });
  }
  
  saveNew() {
    this.cancelNew();
    let msg = {
      Posted_Date: this.getTimestamp(),
      Posted_By: this.authService.getUser().badgeNo,
      MessageType: "system_event",
      MessageSubject: this.newMessageSubject,
      MessageBody: this.newMessage
    }
    this.adminService.addAdminMessage(msg, this.authService.getUser().badgeNo).subscribe({
      next: data => {
        console.log("Save message response: " + data['body']);
        this.getMessages();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on saving message: \n" + error.error);
        }
        console.log("Save message error:");
        console.log(error);
      }
    });
  }
  
  ngOnInit() {
    this.activeMsgOpts.push({ label: "Active", value: "active" });
    this.activeMsgOpts.push({ label: "All", value: "all" });
    this.activeMsgChoice = "active";
    this.getMessages();
   }

   getMessages() {
      this.adminService.getAdminMessages(this.activeMsgChoice == 'active').subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.messages = json;
        this.resultsLoading = false;
        this.stripTimeFromDates();
        this.selectedMessage = this.messages[0];
      },
      error: error => {
        console.log(error);
      }
    });
   }
  
    stripTimeFromDates() {
      this.messages.forEach( m => { m.Posted_Date = m.Posted_Date.split("T")[0] } );
    }
}
