import { Component, OnInit } from '@angular/core';
import { SettingsTemplatesService } from './settings-templates.service';
import { ViewEditService } from '../viewedit/viewedit.service';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import { AuthService } from '../auth/auth.service';
import { Router } from "@angular/router";
import { CalendarService } from '../calendar/calendar.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'message-settings',
  templateUrl: './settings-templates.component.html'
})
  
export class SettingsTemplatesComponent implements OnInit {

  domains = [];
  selectedDomain;
  templates = [];
  selectedTemplate;
  templateBeingEdited = {};
  resultsLoading = true;
  bodyTypes = [];
  bodyType;
  msgs: Message[];
  copyTemplateDisplayed = false;
  templatesToCopy = [];
  templateToCopy;

  constructor(private messageService: SettingsTemplatesService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private calendarService: CalendarService) {
   }

  saveTemplate() {
    if (this.templateBeingEdited['Enabled']) {
      this.templateBeingEdited['Enabled'] = 1;
    } else {
      this.templateBeingEdited['Enabled'] = 0;
    }

    console.log(JSON.stringify(this.templateBeingEdited));
    this.messageService.editMessageTemplate(this.authService.getBadgeNo(), this.templateBeingEdited).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({severity: "success", summary:'Success', detail: "Template saved successfully"});
        console.log(data);
        this.getTemplates(false);
      },
      error: error => {
        if (error.status != 401) {
                alert("Error on saving template: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }
  
  ngOnInit() {
    this.bodyTypes.push({ label: "Plain text", value: "plain" });
    this.bodyTypes.push({ label: "HTML", value: "html" });
    this.bodyType = "plain";
    this.getDomains(); 
  }

  showCopyTemplate() {
    this.getMessageTemplatesToCopy();
    this.copyTemplateDisplayed = true;
  }

  copyTemplate() {
    let copy = { NameTemplate: this.templateToCopy };
    console.log(this.templateToCopy);
    this.messageService.copyMessageTemplate(this.authService.getBadgeNo(), 
      this.selectedDomain.domain, copy).subscribe({
      next: data => {
        this.cancelCopyTemplate();
        this.getTemplates(true);
      },
      error: err => {
        alert("Error on copying template: \n" + err.error);
        console.log(err.error);      }
    });
      
  }

  cancelCopyTemplate() {
    this.copyTemplateDisplayed = false;
  }

  getDomains() {
      this.messageService.getMessageDomains(this.authService.getBadgeNo()).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach( j => { this.domains.push( { domain: j })});
        this.resultsLoading = false;
      },
      error: error => {
        alert("Error on getting domains: \n" + error.error);
        console.log(error.error);
      }
    });
   }
  
   getTemplates(wipeExistingSelections) {
      if (wipeExistingSelections) {
        this.selectedTemplate = null;
        this.templateBeingEdited = {};
      }

      this.templatesToCopy = [];
      this.messageService.getMessageTemplatesForDomain(this.selectedDomain.domain.replace("/", "slash"), this.authService.getBadgeNo()).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.templates = json;
          this.resultsLoading = false;
        } else {
          this.templates = [];
          console.log("No templates found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting templates: \n" + error.error);
        }
        console.log(error.error);
      }
    });
   }

  getMessageTemplatesToCopy() {
      this.messageService.getMessageTemplatesToCopy(this.selectedDomain.domain, this.authService.getBadgeNo()).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          json.forEach( t => {
            this.templatesToCopy.push({ value: t.NameTemplate, label: t.NameTemplate });
          });
          this.resultsLoading = false;
        } else {
          this.templates = [];
          console.log("No templates to copy found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting templates to copy for domain: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
   }

    getMessageTemplatesForDomain() {
      this.messageService.getMessageTemplatesForDomain(this.selectedDomain.domain, this.authService.getBadgeNo()).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.templates = json;
          this.resultsLoading = false;
        } else {
          this.templates = [];
          console.log("No templates found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting templates for domain: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
   }

     getFullMessageDetails() {
      this.messageService.getFullMessageDetails(this.authService.getBadgeNo(), 
        this.selectedDomain.domain, this.selectedTemplate.NameTemplate).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          console.log(json);
          this.templateBeingEdited = json[0];
          this.resultsLoading = false;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting message details: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
   }
  
}
