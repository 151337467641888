import { Component, Input } from '@angular/core';
import { MenubarModule, MenuItem } from 'primeng/primeng';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { AppService } from './app.service';
import { ActivatedRoute } from "@angular/router"; // ActivatedRoue is used to get the current associated components information.
import { UserDetailsService } from './login/user-details.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
  
export class AppComponent {

    items;
    isDev = false;
    activeItem = 2;
    permissions = [];
    viewingPermissions = false;
    userBeingSimulated;
    simulateUserDisplayed;
  
    constructor(private router: Router, private authService: AuthService,
      private appService: AppService,
      private userDetailsService: UserDetailsService,
      private actRoute: ActivatedRoute ) {
      
      this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              let route = event.url.split("/")[1].split(";")[0];
              this.highlightCorrectTab(route);
            }
        });
    }

    logOut() {
      this.authService.logout();
    }
  
    logIn() {
      this.router.navigateByUrl("/login");
    }
  
    isLoginPage() {
      return this.router.url == "/login";
    }
  
    viewPermissions() {
      this.permissions = this.authService.getUser() ? this.authService.getUser().permissions : [];
      console.log(this.permissions);
      this.viewingPermissions = true;
    }
  
    hidePermissions() {
      this.viewingPermissions = false;
    }
  
    openSimulateUser() {
      this.simulateUserDisplayed = true;
    }
  
    closeSimulateUser() {
      this.simulateUserDisplayed = false;
    }
    
    simulateUser() {
      this.closeSimulateUser();
      this.userDetailsService.getUserDetails(this.userBeingSimulated);
    }
  
    ngOnInit() {
      let urlRoot = this.authService.getUrlRoot();
      if (urlRoot.indexOf("mis7") >= 0 || urlRoot.indexOf("localhost") >= 0) {
        this.isDev = true;
      }
      
      this.createTabs();
      let self = this;
      this.userDetailsService.userInfoLoaded.subscribe(function(user) { if (user != null ) { console.log("in userDetailsLoaded. User = " + user['fullName']); self.createTabs(); } });
      
    }  
  
    createTabs() {
            this.items = [];
      this.items.push({ label : "Overview", 
          route: "overview",
            command: (event) => { this.router.navigate(['overview']); }
            });
      this.items.push({ label : "Create Schedule", route: "createschedule",
            command: (event) => { this.router.navigate(['createschedule']); }
            });    
      this.items.push({ label : "View/Edit Schedule", route: "viewedit", 
            command: (event) => { this.router.navigate(['viewedit']); }
            });
      this.items.push({ label : "View All", route: "viewall",
            command: (event) => { this.router.navigate(['viewall']); }
            });      
      this.items.push({ label : "Reporting", route: "reporting",
            command: (event) => { this.router.navigate(['reporting']); }
            }); 
      if (this.authService.isSuperAdmin()) {
        this.items.push({ label : "Admin", route: "admin",
            command: (event) => { this.router.navigate(['admin']); }
            });
      }
      if (this.authService.isDomainSuperUser()) {
      this.items.push({ label : "Settings", route: "settings",
            command: (event) => { this.router.navigate(['settings']); }
            });      
      }
      
    }
  
    highlightCorrectTab(route) {
      this.items.forEach(i => { 
        if (i.route == route) {
          this.activeItem = this.items.indexOf(i);
        }
      });
    }
  
    getWelcomeText() {
      if (this.authService.getUser()) {
        return "Welcome, " + this.authService.getUser().fullName;
      } else {
        return "";
      }
    }
  
}
