import { Component, OnInit } from '@angular/core';
import { CreateScheduleService } from './createschedule.service';
import { ViewEditService } from '../viewedit/viewedit.service';
import { AuthService } from '../auth/auth.service';
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from '@angular/router';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'createschedule',
  templateUrl: './createschedule.component.html',
})

export class CreateScheduleComponent implements OnInit {

  year;
  cycle = 1;
  startDate;
  endDate;
  runs = [];
  beamlines = [];
  beamline;
  run;
  scheduleTypes;
  scheduleType;
  scheduleName;
  viewScheduleYear;
  viewScheduleRuns;
  selectedRun;
  viewScheduleSchedules;
  selectedSchedule;
  msgs: Message[];
  scheduleTypeEnabled = true;
  showCreateRunPanel = false;
  showCreateSchedulePanel = false;
  showCreateScheduleTypeRun = false;
  showCreateScheduleTypeBeamline = false;
  showCreateScheduleRun = false;
  showCreateScheduleScheduleName = false;
  allowedToCreateRunSchedules = false;
  runScheduleExistsForSelectedRun = false;
  showBeamlineScheduleOption = false;

  constructor(private scheduleService: CreateScheduleService,
              private viewEditService: ViewEditService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.viewScheduleYear = new Date().getFullYear();
    this.populateRunsForViewSchedules();
    this.scheduleTypes = [ { value: "Run", label: "Run" },
                  { value: "Beamline", label: "Beamline" }];
    this.scheduleType = "Beamline";
    this.populateRuns();
  }
  
  clearScheduleName() {
    this.scheduleName = "";
  }
  
  isDomainSuperUser() {
    return this.authService.isDomainSuperUser();
  }
  
  validateRun() {
    if (!this.startDate || !this.endDate) {
      alert("Both a run start and end date must be entered");
      return false;
    }
    
    if (this.startDate.getTime() > this.endDate.getTime()) {
      alert("Start date must be before end date");
      return false;
    }
    
    let yearStart = new Date(this.year + "/1/1");
    if (this.startDate.getTime() < yearStart.getTime() || this.endDate.getTime() < yearStart.getTime()) {
      alert("Both dates must be after the start of the selected year");
      return false;
    }
    
    return true;
  }

  isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }
  
  getFormattedDate(date: Date) {
    return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() 
    + " " + date.getHours() + ":" + date.getMinutes() + ":00";
  }
  
  goToViewSchedule() {
    console.log(this.selectedSchedule);
    let params = { run: this.selectedSchedule.RunName };
    if ( this.selectedSchedule.Schedule_Type == "Beamline" ) {
      params['beamline'] = this.selectedSchedule.ScheduleName
    };

    this.router.navigate(["viewedit", params ]);
  }
  
  populateBeamlinesForViewingSchedules() {
    let self = this;
    if (!this.selectedRun) {
      return;
    }
    this.viewEditService.getBeamlinesForRun(this.selectedRun.RunName, "All").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach( j => {
          if (j.CreatedDate) {
            j.CreatedDate = j.CreatedDate.split("T")[0];
          }
          
          j.Published = j.Published == "0" ? "No" : "Yes";
        });
        this.viewScheduleSchedules = json;
        
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }
  
  checkUserRunSchedulePrivilege() {
      let temp = [];
      this.scheduleService.checkUserRunSchedulePrivilege(this.authService.getBadgeNo()).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          if (json['CreateNewBeamlineScheduleAnyDomain']
            || json['CreateNewBeamlineScheduleSpecificDomain']) {
            this.showCreateRunPanel = false;
            this.showCreateSchedulePanel = true;
            this.allowedToCreateRunSchedules = false;
          }  

          if (json['CreateNewRunAndRunSchedule']) {
             this.showCreateRunPanel = true;
              this.showCreateSchedulePanel = true;
              this.allowedToCreateRunSchedules = true;
          }

          if (json['CreateNewBeamlineScheduleSpecificDomain']) {
            this.showBeamlineScheduleOption = true;
          }

          this.checkWhetherRunScheduleCreatedForRun();
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting user edit privileges: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }

  checkWhetherRunScheduleCreatedForRun() {
    this.viewEditService.getBeamlinesForRun(this.run, "All").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        let possibleRunSchedule = json.find( s => { return s.Schedule_Type == "Run" } );
        let newScheduleTypes = [];
        if (this.showBeamlineScheduleOption) {
          newScheduleTypes.push({ value: "Beamline", label: "Beamline" });
        }
        
        if (!possibleRunSchedule) {
          this.runScheduleExistsForSelectedRun = false;
          this.scheduleTypeEnabled = false;
          if (this.allowedToCreateRunSchedules) {
            newScheduleTypes.push({ value: "Run", label: "Run" });
            this.scheduleType = "Run";
          } else {
            this.scheduleType = "Beamline";
          }
          this.populateBeamlinesForCreatingSchedule();
        } else {
          this.scheduleType = "Beamline";
          this.runScheduleExistsForSelectedRun = true;
          this.scheduleTypeEnabled = true;
          this.scheduleType = "Beamline";
          this.populateBeamlinesForCreatingSchedule();
        }
        this.scheduleTypes = newScheduleTypes;
        this.viewScheduleSchedules = json;
        
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  populateBeamlinesForCreatingSchedule() {
    let self = this;
    this.beamlines = [];
    this.scheduleName = "";
    console.log(this.run);
    this.scheduleService.getBeamlinesForSuperUser(this.authService.getBadgeNo(), this.run, "NO").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.beamlines = json;
        if (this.beamlines.length > 0) {
          let selectedBeamline = this.route.snapshot.paramMap.get("beamline");
          if (selectedBeamline) {
            let bm;
            json.forEach( b => { if (b.BeamlineVal == selectedBeamline) { bm =  b; }});
            if (bm) {
              this.beamline = selectedBeamline;
            }
            else {
              this.beamline = this.beamlines[0].BeamlineVal;
            }
          } else {
            this.beamline = this.beamlines[0].BeamlineVal;
          }
           this.setScheduleName();
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines for super user: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }
  
  
   populateRuns() {
    let self = this;
    this.runs = [];
    this.viewEditService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function(r) {
          self.runs.push( { value: r.RunName, label: r.RunName, run: r });
        });
        let selectedRun = this.route.snapshot.paramMap.get("run");
        if (selectedRun) {
          this.run = selectedRun;
        } else {
          this.setCurrentRun(json);
        }
        this.checkUserRunSchedulePrivilege();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }
  
  setCurrentRun(runs) {
    let today = new Date();
  
    runs.forEach( r => { 
      let start = new Date(r.RunStart.split("T")[0]);
      let end = new Date(r.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        this.run = r.RunName;
      }
    });  
  }
  
  populateRunsForViewSchedules() {
    let self = this;
    this.viewScheduleRuns = [];
    this.viewScheduleSchedules = [];
    this.scheduleService.getRunsForYear(this.viewScheduleYear).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(r => { 
          r.RunStart = r.RunStart.replace("T", " "); 
          r.RunEnd = r.RunEnd.replace("T", " "); 
        });
        self.viewScheduleRuns = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }
  
  saveRun() {
    if (!this.validateRun()) {
      return;
    }
    
    let run = {
      RunYear: this.year,
      RunCycle: this.cycle,
      RunStart: this.getFormattedDate(this.startDate),
      RunEnd: this.getFormattedDate(this.endDate)
    };
    
   this.scheduleService.saveRun(run, this.authService.getBadgeNo()).subscribe({
    next: data => {
      this.msgs = [];
      this.msgs.push({severity: "success", summary:'Success', detail: "Run saved successfully"});
      this.populateRuns();
      this.populateRunsForViewSchedules();
      console.log("Save run response: " + data['body']);
    },
    error: error => {
      if (error.status != 401) {
        alert("Error on saving run:\n" + error.error);
      }
      console.log("Error:");
      console.log(error);
    }
  });
  }
  
  validateSchedule() {
    if (!this.scheduleName) {
      alert("A schedule name must be entered");
      return false;
    }
    
    
    return true;
  }
  
  setScheduleName() {
    if (this.scheduleType == "Beamline") {
      this.scheduleName = this.beamline;
    } else {
      this.scheduleName = this.run;
    }
  }
  
  saveSchedule() {
    if (!this.validateSchedule()) {
      return;
    }
    
    let schedule = {
      Schedule_Type: this.scheduleType,
      RunName: this.run,
      Beamline: this.beamline,
      ScheduleName: this.scheduleName
    };
    
   this.scheduleService.saveSchedule(schedule, this.authService.getBadgeNo()).subscribe({
    next: data => {
      console.log("Save schedule response: " + data['body']);
      this.msgs = [];
      this.msgs.push({severity: "success", summary:'Success', detail: "Schedule saved successfully"});
      this.populateBeamlinesForViewingSchedules();
      this.checkWhetherRunScheduleCreatedForRun();
    },
    error: error => {
        if (error.status != 401) {
          alert("Error on saving schedule: \n" + error.error);
        }
        console.log(error.error);      
    }
  });
  }
}
