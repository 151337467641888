import { Component, OnInit } from '@angular/core';
import { Constants } from '../shared/constants';
import { ReportingBeamtimeUsageService } from './reporting-beamtime-usage.service';
import { SelectItem } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import * as fileSaver from 'file-saver';

interface Book {
  name: string;
  author: string;
}

@Component({
  selector: 'reporting-beamtime-usage',
  templateUrl: './reporting-beamtime-usage.component.html',
})
export class ReportingBeamtimeUsageComponent implements OnInit {

  resultsLoading = true;
  runs = [];
  selectedRunBeamtimeUsage;
  runsForBeamtimeUsageReport = [];
  selectedRunBeamtimeList;

  constructor(
    private authService: AuthService,
    private reportingService: ReportingBeamtimeUsageService) {
  }

  addRunToList() {
    if (!this.selectedRunBeamtimeUsage) {
      return;
    }
    
    let index = this.runsForBeamtimeUsageReport.findIndex( i => i.value == this.selectedRunBeamtimeUsage );

    if (index < 0) {
      let slr = { value: this.selectedRunBeamtimeUsage, label: this.selectedRunBeamtimeUsage };
      this.runsForBeamtimeUsageReport.push(slr);
      this.runsForBeamtimeUsageReport = JSON.parse(JSON.stringify(this.runsForBeamtimeUsageReport));
    }
  }

  downloadBeamtimeUsage() {
    console.log("getting BeamtimeUsage report");
    this.resultsLoading = true;
    let runs = this.runsForBeamtimeUsageReport.map((i: any) =>
              ( i.value )
            );
    let runsParam = runs;
    this.reportingService.downloadBeamtimeUsage(
        runsParam).subscribe({
      next: data => {
        let d:string = data;
        while (d.indexOf("\\n") >= 0) {
          d = d.replace("\\n", "\n").replace("\\\"", "\"");
        }        
        let blob: any = new Blob([d]);
        this.resultsLoading = false;
        fileSaver.saveAs(blob, "Beamtime Usage Report.csv");
      },
      error: error => {
        this.resultsLoading = false;
        if (error.status != 401) {
          alert("Error on downloading Beamtime Usage report: \n" + error.error);
        }
        console.log(error);
      }
    });
  }

  removeRunFromList() {
    let index = this.runsForBeamtimeUsageReport.findIndex( i => i.value == this.selectedRunBeamtimeUsage );
    this.runsForBeamtimeUsageReport.splice(index, 1);
    this.runsForBeamtimeUsageReport = JSON.parse(JSON.stringify(this.runsForBeamtimeUsageReport));
    this.selectedRunBeamtimeList = "";
  }

  populateRuns() {
    let self = this;
    this.runs = [];
    this.reportingService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runs.push({ value: r.RunName, label: r.RunName, run: r });
        });
        this.resultsLoading = false;
        let currentRun = this.getCurrentRun(this.runs);
        console.log(currentRun);
        if (currentRun) {
          self.selectedRunBeamtimeUsage = currentRun.RunName;
        }
      },
      error: error => {
        if (error.status != 401) {        
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun(runs) {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    return currentRun;
  }

  ngOnInit() {

    this.populateRuns();
  }

}
