import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from '../shared/constants';
import { ViewEditService } from './viewedit.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import { CalendarService } from '../calendar/calendar.service';
import { CalendarComponent } from '../calendar/calendar.component';

interface Book {
  name: string;
  author: string;
}

@Component({
  selector: 'viewedit',
  templateUrl: './viewedit.component.html',
})
export class ViewEditComponent implements OnInit {

  runs = [];
  run;

  beamlines = [];
  beamline;
  defaultShiftHours;
  events = [];
  runTypes = [];
  runType;
  resultsLoading = true;
  oneDay = 86400000;
  fullBeamlineData;
  published;
  ignoreRunParameter = false;
  ignoreBeamlineParam = false;
  beamlinesForSuperUser = [];
  statistics = [];
  beamlineEditabilityPolicy;
  runDetails: any;
  activityTypes = [];
  warnings = [];
  guidance = [];
  useCurrentMonth = true;
  units = "Shifts";
  width = 900;

  @ViewChild("calendar", { static: false }) calendar: CalendarComponent;

  constructor(
    private viewEditService: ViewEditService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private createScheduleService: CreateScheduleService,
    private calendarService: CalendarService,
    private confirmationService: ConfirmationService) {
    let currentMonthParam = this.route.snapshot.paramMap.get("useCurrentMonth");
    if (!currentMonthParam) {
      this.useCurrentMonth = true;
    } else {
      this.useCurrentMonth = currentMonthParam == "true";
    }
  }



  getBeamlinesForSuperUser() {
    this.createScheduleService.getBeamlinesForSuperUser(this.authService.getBadgeNo(), "2021-1", "ALL").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.beamlinesForSuperUser = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getRunActivityColorLegend() {
    this.viewEditService.getRunActivityColorLegend().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.activityTypes = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting activity types: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getScheduleWarnings() {
    this.viewEditService.getScheduleWarnings(this.beamline,
      this.run,
      this.authService.getBadgeNo()).subscribe({
        next: data => {
          let json = JSON.parse(data['body']);
          this.warnings = json;
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting schedule warnings: \n" + error.error);
          }
          console.log(error.error);
        }
      });
  }

  highlightActivity(activityId) {
    this.calendar.highlightActivity(activityId);
  }

  getScheduleGuidance(gupBeamtimeId: String) {
    if (!gupBeamtimeId) {
      this.guidance = [];
      return;
    }
    this.viewEditService.getScheduleGuidance(this.beamline,
      this.run,
      gupBeamtimeId,
      this.authService.getBadgeNo()).subscribe({
        next: data => {
          let json = JSON.parse(data['body']);
          this.guidance = json;
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting schedule guidance: \n" + error.error);
          }
          console.log(error.error);
        }
      });
  }

  getScheduleStatistics() {
    this.viewEditService.getScheduleStatistics(this.beamline, this.run).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.statistics = json;
        if (this.statistics.length > 0) {
          this.units = this.statistics[0].Units
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting statistics: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  showPublishButton() {
    if (!this.authService.getAuthenticated()) {
      return false;
    }

    if (this.runType == "Beamline") {
      return this.isSuperUserForCurrentBeamline();
    } else {
      return this.authService.isSuperAdmin();
    }
  }

  isSuperUserForCurrentBeamline() {
    return this.beamlinesForSuperUser.find(b => b.BeamlineVal == this.beamline);
  }

  populateRuns() {
    let self = this;
    this.runs = [];
    this.viewEditService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runs.push({ value: r.RunName, label: r.RunName, run: r });
        });
        let selectedRun = this.route.snapshot.paramMap.get("run");
        if (selectedRun && !this.ignoreRunParameter) {
          self.run = selectedRun;
          this.ignoreRunParameter = true;
        } else {
          self.run = this.getCurrentRun().RunName;
        }
        self.populateBeamlines(true);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun() {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    this.runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    if (!currentRun) {
      alert("No current run is found. Therefore, the calendar \n won't display properly until a run is selected.");
    }
    return currentRun;
  }

  getRunDetails() {
    let self = this;
    this.calendarService.getRunDetail(this.run).subscribe({
      next: data => {
        self.runDetails = JSON.parse(data['body']);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting run details: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  populateBeamlines(resetToCurrentBeamline) {

    let currentBeamline = this.beamline;
    this.getRunDetails();
    let self = this;
    this.beamlines = [];
    let counter = 0;
    this.viewEditService.getBeamlinesForRun(this.run, this.runType).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        self.fullBeamlineData = json;
        if (json.length == 0) {
          alert("There are no schedules defined for run " + this.run);
        }
        else {
          let options;

          if (json[0].Beamline) {
            options = json.map((i: any) =>
              ({ label: i.Beamline, value: i.Beamline })
            );
          } else {
            options = json.map((i: any) =>
              ({ label: i.ScheduleName, value: i.ScheduleName })
            );

          }
          self.beamlines = options;
          let beamlineParam = this.route.snapshot.paramMap.get("beamline");
          if (beamlineParam && !this.ignoreBeamlineParam) {
            self.beamline = beamlineParam;
            this.ignoreBeamlineParam = true;
          } else {
            if (self.currentBeamlineIsInList(currentBeamline)) {
              self.beamline = currentBeamline;
            } else {
              if (this.beamlinesForSuperUser.length > 0) {
                this.setDefaultBeamlineForUser();
              } else {
                self.beamline = self.beamlines[0].value;
              }
            }
          }
          self.getActivities();
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  setDefaultBeamlineForUser() {
    let defaultSet = false;
    this.beamlines.forEach(v => {
      if (!defaultSet && this.beamlinesForSuperUser.find(b => { return v.value == b.BeamlineVal })) {
        this.beamline = v.value;
        defaultSet = true;
      }
    });

    if (!defaultSet && this.beamlines.length > 0) {
      this.beamline = this.beamlines[0].value;
    }
  }

  currentBeamlineIsInList(currentBeamline) {
    return this.beamlines.find(b => b.value == currentBeamline);
  }

  getFullDataForSelectedSchedule() {
    return this.fullBeamlineData.find(b => b.ScheduleName == this.beamline);
  }

  ngOnInit() {
    this.runTypes = [{ value: "Run", label: "Run" },
    { value: "Beamline", label: "Beamline" }];
    let selectedBeamline = this.route.snapshot.paramMap.get("beamline");
    let selectedRun = this.route.snapshot.paramMap.get("run");
    let showBeamlineSchedulesWithoutActuallySelectingOne = this.route.snapshot.paramMap.get("scheduleTypeBeamline");

    if (selectedRun) {
      if (selectedBeamline) {
        this.runType = "Beamline";
      } else if (showBeamlineSchedulesWithoutActuallySelectingOne) {
        this.runType = "Beamline";
      } else {
        this.runType = "Run";
      }
    } else {
      this.runType = "Beamline";
    }
    this.populateRuns();
    this.getBeamlinesForSuperUser();
    this.getRunActivityColorLegend();
  }

  setPublishedStatus() {
    let scheduleData = this.getFullDataForSelectedSchedule();
    if (scheduleData) {
      this.published = scheduleData.Published == 1 ? "Published" : "Not published";
    } else {
      this.published = "";
    }
  }

  publishSchedule() {
    let schedule = {
      RunName: this.run,
      ScheduleName: this.beamline
    };
    this.viewEditService.publishSchedule(this.authService.getBadgeNo(),
      schedule,
      this.runType == "Run").subscribe({
        next: data => {
          this.populateBeamlines(true);
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on publishing schedule: \n" + error.error);
          }
          console.log(error.error);
        }
      });
  }

  unpublishSchedule() {
    let schedule = {
      RunName: this.run,
      ScheduleName: this.beamline
    };
    this.viewEditService.unpublishSchedule(this.authService.getBadgeNo(),
      schedule,
      this.runType == "Run").subscribe({
        next: data => {
          this.populateBeamlines(true);
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on unpublishing schedule: \n" + error.error);
          }
          console.log(error.error);
        }
      });
  }


  checkUserActivityEditPrivilege() {
    let temp = [];
    this.calendarService.checkUserActivityEditPrivilege(this.authService.getBadgeNo(), this.beamline, this.runType).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.beamlineEditabilityPolicy = json;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting user edit privileges: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  syncBeamline() {
    this.confirmationService.confirm({
      message: 'Your beamline schedule needs to be updated\n'
        + 'as the underlying run schedule has changed\n'
        + 'Do you want to update it?',
      key: "sync",
      accept: () => {
        this.viewEditService.syncBeamlineToRunSchedule(this.authService.getBadgeNo(), this.beamline, this.run).subscribe({
          next: data => {
            if (data) {
              let json = JSON.parse(data['body']);
              this.getActivities();
            }
          },
          error: error => {
            if (error.status != 401) {
              alert("Error on syncing beamline schedule: \n" + error.error);
            }
            console.log(error.error);
          }
        });

      }
    });
  }

  checkBeamlineInSync() {
    this.viewEditService.checkBeamlineInSync(this.authService.getBadgeNo(), this.beamline, this.run).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body'])[0];
          let outOfSync = json['OutOfSync'];
          if (outOfSync > 0) {
            this.syncBeamline();
          }
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on checking if beamline schedule in sync: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  checkIfUserCanEditActivitiesAndWarnIfBeamlineScheduleOutOfSync() {
    this.beamlineEditabilityPolicy = {};
    this.checkUserActivityEditPrivilege();
    this.checkBeamlineInSync();
  }

  selectedRunIsInPreviousFY() {
    if (!this.runDetails) {
      return false;
    }

    let runEnd = new Date(this.runDetails.defaultStartDate = new Date(this.runDetails.RunEnd.replace("T", " ")));
    return this.isInPreviousFY(runEnd);
  }

  isInPreviousFY(date: Date) {
    let now = new Date();
    if (date.getFullYear() >= now.getFullYear()) {
      return false;
    }

    if (date.getFullYear() < (now.getFullYear() - 1)) {
      return true;
    }

    // now check month
    if (date.getMonth() + 1 <= 9) {
      return true;
    }

    return false;
  }

  getDefaultShiftHours() {
    if (this.runType == "Beamline") {
      this.viewEditService.getDefaultShiftHours(this.authService.getBadgeNo(), this.beamline).subscribe({
        next: data => {
          if (data) {
            let json = JSON.parse(data['body']);
            this.defaultShiftHours = json;
          } else {
            console.log("No shift hours setting found for " + this.beamline);
          }
        },
        error: error => {
          console.log("Can't get deafult shift hours for beamline - this could be because it's a run schedule.");
          console.log(error.error);
        }
      });
    }
  }

  getActivities() {
    this.getScheduleStatistics();
    this.getScheduleWarnings();
    this.checkIfUserCanEditActivitiesAndWarnIfBeamlineScheduleOutOfSync();
    this.setPublishedStatus();
    this.getDefaultShiftHours();
    this.viewEditService.getActivities(this.beamline, this.run).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.createEventsFromActivities(json);
        this.resultsLoading = false;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting activities: \n" + error.error);
        }
        console.log(error.error);
        this.resultsLoading = false;
      }
    });
  }

  createEventsFromActivities(act) {
    let newEvents = [];
    let self = this;
    act.forEach(function (a) {
      a.Time_Unused = a.Time_Unused == "true" ? true : false;
      newEvents.push({
        startDate: a.StartDate,
        endDate: a.EndDate,
        title: a.Activity_Type,
        color: a.Display_Color,
        start: 0,
        end: 0,
        startAsDateObject: 0,
        endAsDateObject: 0,
        overlap: -1,
        eventFromApi: a
      });
    });

    this.events = newEvents;
  }
}
