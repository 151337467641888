import { Component, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { ViewAllService } from './viewall.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'viewall',
  templateUrl: './viewall.component.html',
})
export class ViewAllComponent implements OnInit {

  messages = [];
  runs = [];
  resultsLoading = true;
  run;
  techniques = [];
  selectedTechniques = [];
  weeks = [];
  oneDay = 86400000;
  oneWeek = this.oneDay * 7;
  oneYear = this.oneDay * 365;
  //events = [];
  rows = [];
  width = 1000;
  runStartDate: Date;
  runEndDate: Date;
  domains = [];
  domain = "All";
  showEmpty = false;
  startOfFirstActivity;
  endOfLastActivity;
  showFullRunButton = false;
  rowCounter = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private viewAllService: ViewAllService) {
  }

  ngOnInit() {
    this.populateRuns();
    this.populateDomains();
    this.populateTechniques();
  }

  populateRuns() {
    let self = this;
    this.runs = [];
    this.viewAllService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runs.push({ value: r.RunName, label: r.RunName, run: r });
        });
        let currentRun = this.getCurrentRun(self.runs);
        self.run = currentRun.RunName;
        this.getViewAllActivitySummary(false);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getRowBackgroundColor(i) {

    if (i <= 1) {
      return "#FFFFFF";
    } else {
      if (i % 2 == 0) {
        return "#EEEEEE";
      } else {
        return "#FFFFFF";
      }
    }
  }

    
  showFullRun() {
    this.showFullRunButton = false;
    this.getViewAllActivitySummary(false);
  }

  zoomIn(startMillis, lengthMillis) {
    this.showFullRunButton = true;
    this.startOfFirstActivity = startMillis - 1000;
    this.endOfLastActivity = startMillis + lengthMillis + 1000;
    this.getViewAllActivitySummary(true);
  }

  goToViewSchedule(event) { 
    if (event.isTimePeriod) {
      this.zoomIn(event.startMillis, event.lengthMillis);
    } else if (event.ScheduleType != "Run") {
      let params = { run: this.run, beamline: event.RunBeamlineName };
      this.router.navigate(["viewedit", params ]);
    }
  }

  getViewAllActivitySummary(isZoom: boolean) {
    this.resultsLoading = true;
    this.rows = [];
    this.rowCounter = 0;
    let selectedRun = this.runs.find( r => { return r.label == this.run } );
    
    this.runStartDate = new Date(selectedRun.run.RunStart.replace("T", " "));
    this.runEndDate = new Date(selectedRun.run.RunEnd.replace("T", " "));

    let techniques = "";
    this.selectedTechniques.forEach( t => { techniques = techniques + t.Technique_ID + ","});

    let self = this;
    this.viewAllService.getViewAllActivitySummary(this.authService.getBadgeNo(),
      this.run,
      this.domain,
      this.showEmpty,
      techniques).subscribe({
        next: data => {
          let json = JSON.parse(data['body']);
          let activities = json;
          this.resultsLoading = false;
          this.setDatesOnActivities(activities);
          let rows = this.sortActivitiesIntoRows(activities); 
          rows.forEach(r => { this.sortOverlappingEvents(r)});
          rows = this.splitOverlappingEventsOutIntoNewRows(rows);
          if (!isZoom) {
            this.startOfFirstActivity = this.getEarliestTime(rows);
            this.endOfLastActivity = this.getLatestTime(rows); 
          }
          this.createWeeksRow(rows);
          this.createMonthsRow(rows);
          if (isZoom) {
            this.cropActivities(rows);
          }
          this.makeLeftMarginsOfActivitiesRelative(rows);
          this.rows = rows;
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting activity summary: \n" + error.error);
          }
          console.log(error.error);
        }
      });
  }

  cropActivities(rows: any[]) {
    let lengthMillis = this.endOfLastActivity - this.startOfFirstActivity;

    rows.forEach(r => {
      r.forEach(a => {
        
        if (a.DisplayTitle == "Wk 5") {
          console.log(a)
        }
        if (a.endMillis < this.startOfFirstActivity || a.startMillis > this.endOfLastActivity) {
          a.display = false;
        } else if (a.startMillis < this.startOfFirstActivity) {
          a.startMillis = this.startOfFirstActivity;
          a.display = true;
        } else if (a.endMillis > this.endOfLastActivity) {
          a.endMillis = this.endOfLastActivity;
          a.display = true;
        }
        let leftMargin = ((a.startMillis - this.startOfFirstActivity) / lengthMillis) * this.width;
        a.leftMargin = leftMargin;
        a.width = ((a.endMillis - a.startMillis) / lengthMillis) * this.width;
      });
    });

  }

  getEarliestTime(rows) {
    let earliest = rows[0][0].startMillis;
    rows.forEach(r => { 
      if (r[0].startMillis < earliest) {
        earliest = r[0].startMillis;
      }
    });
    return earliest;
  }

  getLatestTime(rows) {
    let latest = rows[0][rows[0].length - 1].endMillis;
    rows.forEach(r => { 
      let end = r[r.length - 1].endMillis;
      if (end > latest) {
        latest = end;
      }
    });
    return latest;
  }

  createMonthsRow(rows) {
    let lengthMillis = this.endOfLastActivity - this.startOfFirstActivity;
    let date = this.runStartDate;
    let row = [];

    while (date.getTime() < this.runEndDate.getTime()) {
      if (date.getDate() == 1) {
        let leftMargin = ((date.getTime() - this.startOfFirstActivity) / lengthMillis) * this.width;
        let endOfMonth = new Date(date.getTime());
        endOfMonth.setMonth(date.getMonth() + 1);
        row.push({
          startMillis: date.getTime(),
          lengthMillis: endOfMonth.getTime() - date.getTime(),
          endMillis: endOfMonth.getTime(),
          leftMargin: leftMargin,
          display: true,
          DisplayTitle:  date.toLocaleString('default', { month: 'long' }),
          isTimePeriod: true,
          width: ((endOfMonth.getTime() - date.getTime()) / lengthMillis) * this.width

        })
      }
      date = new Date(date.getTime() + this.oneDay);
    }
    rows.unshift(row);
  }


  createWeeksRow(rows) {
    let lengthMillis = this.endOfLastActivity - this.startOfFirstActivity;
    let row = [];

    let date = this.runStartDate;
    let weekNum = this.getWeekNum(this.runStartDate);

    while (date.getTime() < this.runEndDate.getTime()) {
      if (date.getDay() == 1) {
        let leftMargin = ((date.getTime() - this.startOfFirstActivity) / lengthMillis) * this.width;
        row.push({
          startMillis: date.getTime(),
          lengthMillis: this.oneWeek,
          endMillis: date.getTime() + this.oneWeek,
          leftMargin: leftMargin,
          display: true,
          DisplayTitle:  "Wk " + weekNum,
          isTimePeriod: true,
          width: (this.oneWeek / lengthMillis) * this.width

        })
        weekNum++;
      }
      date = new Date(date.getTime() + this.oneDay);
    }

    rows.unshift(row);
  }
  
  splitOverlappingEventsOutIntoNewRows(rows: any[]) {
    let newRows = [];

    rows.forEach(r => {
      let newRow1 = [];
      let newRow2 = [];
      let newRow3 = [];

      r.forEach( a => {
        if (a.overlap == 0) {
          newRow1.push(a);
        }
        if (a.overlap == 1) {
          newRow2.push(a);
        }
        if (a.overlap == 2) {
          newRow3.push(a);
        }
      })
              
      newRows.push(newRow1);
      if (newRow2.length > 0) {
        newRows.push(newRow2);
      }

      if (newRow3.length > 0) {
        newRows.push(newRow3);
      }

    })
    return newRows;
  }

  setDatesOnActivities(activities) {
    let runStartMillis = this.runStartDate.getTime();
    let runEndMillis = this.runEndDate.getTime();
    let runLengthMillis = runEndMillis - runStartMillis;

    activities.forEach(event => {
      if (!event.StartDate) {
        event.display = false;
        event.overlap = 0;
      } else {
        event.display = true;
        event.isTimePeriod = false;
        event.toolTip = event.DisplayDate + "\n" + event.DisplayTitle;
        event.StartDate = event.StartDate.replace("T", " ");
        event.startMillis = new Date(event.StartDate + "+00:00").getTime();
        event.EndDate = event.EndDate.replace("T", " ");
        event.endMillis = new Date(event.EndDate + "+00:00").getTime();
        event.color = event.HexDisplayColor;
        event.overlap = 0;
        event.leftMargin = ((event.startMillis - runStartMillis) / runLengthMillis) * this.width;
        event.width = ((event.endMillis - event.startMillis) / runLengthMillis) * this.width;
      }
    });
  }

  
  eventsOverlap(e1, e2) {
    return (e1.startMillis > e2.startMillis && e1.startMillis < e2.endMillis
    || e1.endMillis > e2.startMillis && e1.endMillis < e2.endMillis)
    && e1.overlap == e2.overlap;
  }
  
  sortOverlappingEvents(row: any[]) {
    for (let i = 0; i <= 3; i++) {
      for (let n = 0; n < row.length; n++) {
        let outer = row[n];
        for (let m = 0; m < row.length; m++) {
          let eventsOverlap = false;
          let inner = row[m];
          if (this.eventsOverlap(outer, inner)) {
              inner.overlap = inner.overlap + 1;
          }
        }
      }
    }
  }

  makeLeftMarginsOfActivitiesRelative(rows) {
    rows.forEach(r => {
      let rightSideOfLastEvent = 0;
      r.forEach( a => {
        if (a.display) {
          a.leftMargin = a.leftMargin - rightSideOfLastEvent;
          rightSideOfLastEvent = rightSideOfLastEvent + a.leftMargin + a.width;
        }
      });
    })
  }

  sortActivitiesIntoRows(activities) {
    let oldRunBeamlineName = null;
    let row = [];
    let rows = [];
    activities.forEach(a => {
      
      if (a.RunBeamlineName != oldRunBeamlineName) {
        if (oldRunBeamlineName) {
          rows.push(row);
        }
        oldRunBeamlineName = a.RunBeamlineName;
        row = [];
      }
      row.push(a);

    });

    rows.push(row);
    return rows;
  }


  populateTechniques() {
    let self = this;
    this.viewAllService.getAllTechniques().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.techniques = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting techniques: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }


  populateDomains() {
    let self = this;
    this.viewAllService.getViewAllDomains().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.domains = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting domains: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun(runs) {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    return currentRun;
  }

  getWeekStartForDate(date: Date) {

    while (date.getDay() > 0) {
      date = new Date(date.getTime() - this.oneDay);
    }

    return date;
  }


  getWeekNum(weekStart: Date) {
    let daysIntoYear = (weekStart.getTime() % this.oneYear) / this.oneDay;
    return Math.trunc(daysIntoYear / 7) - 1;
  }
}
