import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class ViewEditService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  getDefaultShiftHours(badgeNo, beamline) {
    let url = this.authService.getUrlRoot() + '/data/getShiftHoursByBeamline/' 
      + badgeNo + "/" + beamline;
    return this.unsecureHttp.get(url);
  }

  publishSchedule(badgeNo, schedule, isRun) {
    let endPoint = "/data/publish" + (isRun ? "Run" : "Beamline") + "Schedule/";
    let url = this.authService.getUrlRoot() + endPoint + badgeNo;
    return this.unsecureHttp.post(url, schedule);
  }
    
  unpublishSchedule(badgeNo, schedule, isRun) {
    let endPoint = "/data/unpublish" + (isRun ? "Run" : "Beamline") + "Schedule/";
    let url = this.authService.getUrlRoot() + endPoint + badgeNo;
    return this.unsecureHttp.post(url, schedule);
  }
 
  getActivities(beamline, run) {
    let url = this.authService.getUrlRoot() + '/data/getActivityByScheduleNameAndRun/'
      + beamline + "/" + run;
    
    return this.unsecureHttp.get(url);
  }

  getRunActivityColorLegend() {
    let url = this.authService.getUrlRoot() + '/data/getRunActivityColorLegend';
    return this.unsecureHttp.get(url);
  }

  checkBeamlineInSync(badgeNo, beamline, run) {
    let url = this.authService.getUrlRoot() + '/data/checkBeamlineInSync/'
    + badgeNo + "/"  
    + beamline + "/" 
    + run;
    return this.unsecureHttp.get(url);
  }

   
  syncBeamlineToRunSchedule(badgeNo, beamline, run) {
    let url = this.authService.getUrlRoot() + '/data/syncBeamlineToRunSchedule/'
    + badgeNo + "/"  
    + beamline + "/" 
    + run;
    return this.unsecureHttp.get(url);
  }
  
  getScheduleStatistics(schedule, run) {
    let url = this.authService.getUrlRoot() + '/data/getScheduleStatistics/'
      + schedule + "/" + run;
    return this.unsecureHttp.get(url);
  }  
 
  getRuns() {
    let url = this.authService.getUrlRoot() + '/data/getRunsByStartEndDates/1-1-2000/1-1-2040';
    
    return this.unsecureHttp.get(url);
  }

   
  getBeamlinesForRun(run, runType) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForRun/" + run + "/" + runType;
    
    return this.unsecureHttp.get(url);
  }
  
       
  getScheduleWarnings(beamline, run, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getScheduleWarnings/'
       + beamline
       + "/" + run
       + "/" + badgeNo;
    return this.unsecureHttp.get(url);
  }

    
  getScheduleGuidance(beamline, run, gupBeamtimeId, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getScheduleGuidance/'
       + beamline
       + "/" + run
       + "/" + gupBeamtimeId
       + "/" + badgeNo;
    return this.unsecureHttp.get(url);
  }

  getBeamlines() {
    let url = this.authService.getUrlRoot() + "/data/getBeamlines";
    
    return this.unsecureHttp.get(url);
  }
} 