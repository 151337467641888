import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class ReportingBeamtimeUsageService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

 
  getRuns() {
    let url = this.authService.getUrlRoot() + '/data/getRunsByStartEndDates/1-1-2000/1-1-2040';
    
    return this.unsecureHttp.get(url);
  }
    
   
  getBeamlinesForRun(run, runType) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForRun/" + run + "/" + runType;
    
    return this.unsecureHttp.get(url);
  }
  
     
  getBeamlines() {
    let url = this.authService.getUrlRoot() + "/data/getBeamlines";
    
    return this.unsecureHttp.get(url);
  }

  downloadBeamtimeUsage(runs) {
    let url = this.authService.getUrlRoot() + "/data/beamtimeUsageReport/" 
      + this.authService.getBadgeNo();
    return this.unsecureHttp.post(url, runs, {responseType: 'text'});
  }
} 