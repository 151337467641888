import { Component, OnInit } from '@angular/core';
import { SettingsAuthorizationService } from './settings-authorization.service';
import { ViewEditService } from '../viewedit/viewedit.service';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import { AuthService } from '../auth/auth.service';
import { Router } from "@angular/router";
import { CalendarService } from '../calendar/calendar.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'settings-authorization',
  templateUrl: './settings-authorization.component.html'
})
  
export class SettingsAuthorizationComponent implements OnInit {

  domains = [];
  selectedDomain;
  domainFilter;
  groups = [];
  selectedGroup;
  roles = [];
  selectedRole;
  resultsLoading = true;
  addGroupDisplayed = false;
  newGroupName;
  groupAuthRole;
  manageMembersDisplayed = false;
  staffSearchResults;
  newMember;
  scopeRoles = [];
  
  constructor(private settingsService: SettingsAuthorizationService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private calendarService: CalendarService) {
   }

    staffSearch(event) {
    let self = this;
    
    if (event.query.length <= 3) {
      return;
    } else {
      this.calendarService.getPeopleInformation(event.query).subscribe({
        next: data => {
          self.staffSearchResults = JSON.parse(data['body']);
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting people information: \n" + error.error);
          }
          console.log(error);
        }
      });
    }
  } 
  
  displayManageMembers() {
    this.newMember = null;
    this.manageMembersDisplayed = true;
  }
  
  cancelManageMembers() {
    this.manageMembersDisplayed = false;
  }
  
  deleteGroup() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this group?',
                 accept: () => {
                    this.settingsService.deleteAuthorizationGroup(this.selectedDomain.domain, 
                            this.selectedGroup, this.authService.getBadgeNo()).subscribe({
                        next: data => {
                          console.log(data);
                          this.getGroups();
                        },
                        error: error => {
                          if (error.status != 401) {
                            alert("Error on deleting group: \n" + error.error);
                          }
                          console.log(error);
                        }
                      }); 
                 }
          });
  }
  
  displayAddGroup() {
    this.newGroupName = "";
    this.addGroupDisplayed = true;
  }
  
  cancelAddGroup() {
    this.addGroupDisplayed = false;
  }
  
  saveNewGroup() {
    this.cancelAddGroup();
    let newGroup = {
      DomainName: this.selectedDomain.domain,
      GroupName: this.newGroupName,
      GroupAuthorizationRole: this.groupAuthRole,
    };
    console.log(JSON.stringify(newGroup));
    this.settingsService.addAuthorizationGroup(newGroup, this.authService.getBadgeNo()).subscribe({
      next: data => {
        console.log(data);
        this.getGroups();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on adding group: \n" + error.error);
        }
        console.log(error);
      }
    });
  }
  
  addNewGroupMember() {
    let member = {
      DomainName: this.selectedDomain.domain,
      GroupName: this.selectedGroup,
      Name: this.newMember.Name,
      BadgeNumberOfUser: this.newMember.Badge_No 
    };
    this.newMember = null;
    this.settingsService.addAuthorizationGroupMember(member, this.authService.getBadgeNo()).subscribe({
      next: data => {
        console.log(data);
        this.getAuthorizationGroupRoles();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on adding group member: \n" + error.error);
        }
        console.log(error);
      }
    });    
  }
  
  deleteGroupMember() {
    console.log(this.selectedRole);
    this.settingsService.deleteAuthorizationGroupMember(this.selectedDomain.domain, 
        this.selectedGroup, 
        this.selectedRole.BadgeNumberOfUser, 
        this.authService.getBadgeNo()).subscribe({
      next: data => {
        console.log(data);
        this.getAuthorizationGroupRoles();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on deleting member: \n" + error.error);
        }
        console.log(error);
      }
    });    
  }
  
  ngOnInit() {
    this.getDomains(); 
    this.getGroupScopeRoles();
  }

  getGroupScopeRoles() {
    this.settingsService.getGroupScopeRoles().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.scopeRoles = [];
        json.forEach( j => { this.scopeRoles.push({ value: j.RoleScope, label: j.RoleScope }) });
        this.groupAuthRole = this.scopeRoles[0].value;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting group members: \n" + error.error);
        }
        console.log(error);
      }
    });    
  }
  
  getDomains() {
      this.settingsService.getAuthorizationDomains(this.authService.getBadgeNo()).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach( j => { this.domains.push( { domain: j })});
        this.resultsLoading = false;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting domains: \n" + error.error);
        }
        console.log(error);
      }
    });
   }
  
   getGroups() {
      this.selectedGroup = null;
      this.roles = [];
      this.settingsService.getAuthorizationGroups(this.selectedDomain.domain.replace("/", "slash"), this.authService.getBadgeNo()).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.groups = json;
          this.resultsLoading = false;
        } else {
          this.groups = [];
          console.log("No groups found for " + this.selectedDomain.domain);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting groups: \n" + error.error);
        }
        console.log(error.error);
      }
    });
   }
  
     getAuthorizationGroupRoles() {
      this.settingsService.getAuthorizationGroupRoles(this.authService.getBadgeNo(),
        this.selectedDomain.domain.replace("/", "slash"), 
        this.selectedGroup).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.roles = json;
          this.resultsLoading = false;
        } else {
          this.roles = [];
          console.log("No roles found for " + this.selectedGroup);
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting auth roles: \n" + error.error);
        }
        console.log(error);
      }
    });
   }
  
}
