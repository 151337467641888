import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpResponse, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../shared/constants';
import { map } from "rxjs/operators";

@Injectable()
export class AuthService {

  private refreshToken: string;
  private expiryTime: number;
  desiredRoute: string;
  
  constructor(private http: HttpClient, private router: Router, 
    @Inject(DOCUMENT) private document: any) {
  }


  getUrlRoot() {
    let fullPath:string = this.document.location.href;
    
    return this.getUrlRootFromPath(fullPath);
  }
  
  getUser(): User {
    let userKey = this.getUrlRoot() + "-user";
    return JSON.parse(localStorage.getItem(userKey));
  }
  
  setUser(user: User) {
    let userKey = this.getUrlRoot() + "-user";
    localStorage.setItem(userKey, JSON.stringify(user));
  }
  
  isSuperAdmin() {
    if (!this.getUser() || !this.getUser().permissions) {
      return false;
    }
    
    let superUser = false;
    this.getUser().permissions.forEach(p => 
      { 
        if (p['DomainName'] == "APS" && (p['DisplayedRole'] == "Super User" || p['DisplayedRole'] == "Domain Administrator")) 
        { 
          superUser = true; }
      });
    return superUser;
  }
  
  isDomainSuperUser() {
    if (!this.getUser() || !this.getUser().permissions) {
      return false;
    }
    
    let superUser = false;
    this.getUser().permissions.forEach(p => 
      { if (p['DisplayedRole'] == "Super User" || p['DisplayedRole'] == "Domain Administrator") 
        { superUser = true; }
      });
    return superUser;    
  }
  
  getBadgeNo() {
    return this.getUser() ? this.getUser().badgeNo : "";
  }
  
  isUserLoggedIn() {
    return this.getUser() ? true : false;
  }
  
  getUrlRootFromPath(fullPath: String) {
    // if running from ng serve, point at local Java back end, else point at
    // wherever app is running
    // case 1: running from ng serve
    if (fullPath.indexOf('4200') > 0) {
      return "http://localhost:8080/" + Constants.devContextRoot;
    }
    else {
      // case 2: running on a port other than 80
      if (fullPath.substring(7).indexOf(":") > 0) {
        let colonPos = 7 + fullPath.substring(7).indexOf(":");
        if (fullPath.substring(colonPos + 6).indexOf("/") < 0) {
          // URL has context root with no slash after it
          return fullPath;
        } else {
          // find up to end of context root
          let slashPos = colonPos + 6 + fullPath.substring(colonPos + 6).indexOf("/");
          return fullPath.substring(0, slashPos);
        }
      } else {
      // case 3: running on a server on port 80 (in other words port not in URL)
        let lastPeriodPos = fullPath.lastIndexOf(".");
        let firstSlashPos = lastPeriodPos + fullPath.substring(lastPeriodPos).indexOf("/"); // get host name
        if (fullPath.substring(firstSlashPos + 1).indexOf("/") < 0) {
          // URL has context root with no slash after it
          return fullPath;
        } else {
          // find up to end of context root
          let secondSlashPos = 1 + firstSlashPos + fullPath.substring(firstSlashPos + 1).indexOf("/"); 
          return fullPath.substring(0, secondSlashPos);
        }
      }
    }
  }
  
    public getAuthenticated(): boolean {
      return this.getUser() != null; // && now < this.expiryTime;
    }
 
    public getToken() {
      let tokenKey = this.getUrlRoot() + "-token";
      return localStorage.getItem(tokenKey);
    }
  
    public setToken(token: string) {
      let tokenKey = this.getUrlRoot() + "-token";
      localStorage.setItem(tokenKey, token);
    }
      
    public getSessionId() {
      let sessionIdKey = this.getUrlRoot() + "-session-id";
      return localStorage.getItem(sessionIdKey);
    }
  
    public setSessionId(id: string) {
      let sessionIdKey = this.getUrlRoot() + "-session-id";
      localStorage.setItem(sessionIdKey, id);
    }
      
    public deleteSessionId() {
      let sessionIdKey = this.getUrlRoot() + "-session-id";
      return localStorage.removeItem(sessionIdKey);
    }

    public deleteToken() {
      let tokenKey = this.getUrlRoot() + "-token";
      localStorage.removeItem(tokenKey);
    }
  
    public deleteUser() {
      let userKey = this.getUrlRoot() + "-user";
      localStorage.removeItem(userKey);
    }
  
      public logout() {
        if (this.getAuthenticated()) {
          this.deleteSessionIdOnServer().subscribe({
            next: data => { 
              console.log("Session ID deleted on server");
            },
            error: err => {
              console.log("Error on deleting session ID on server:\n" + err.error);
            }
          });
      }
        this.deleteUser();
        this.deleteToken();
        this.refreshToken = null;
        this.expiryTime = 0;
        this.router.navigateByUrl('/login');
      }
  
      public deleteSessionIdOnServer() {
        let url = this.getUrlRoot() + '/data/doLogout/' 
           + this.getBadgeNo() + "/" + this.getSessionId();
        return this.http.get(url);
      }

      public login(username:string, password:string) {
        
        let headers = new Headers({
            
        });

        let options = {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
            "Authorization": "Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0"
            })
        };
          
        let loginUrl = this.getUrlRoot() + "/auth/authenticate";
        let separator = "antidisestablishmentarianism";
        let payload: string = btoa(username) + separator + btoa(password);
        while (payload.indexOf("=") >= 0) {
          payload = payload.replace("=", "equals");
        }
        console.log(payload);

        
        return this.http.post(loginUrl, payload, options).pipe(
            map(
            authData => {
              console.log(authData);
                    this.setToken(authData['access_token']);
                    this.refreshToken = authData['refresh_token'];
                    let expirySeconds: number = authData['expires_in'];
                    this.expiryTime = new Date().getTime() + expirySeconds * 1000;
                    this.setUser(new User()); // placeholder until full user info obtained
                    return true; 
            },
        catchError( this.handleError ) 
        ))
        
        
        
    }
  
    handleError( error: HttpErrorResponse ) {
        alert("Error on login: \n" + error.error);

      console.log("error on login: " + error.error);
                this.deleteToken();
                this.deleteUser();
                this.refreshToken = null;
                return Observable.throw(error);
    }


}

export class User {
  badgeNo: string;
  email: string;
  fullName: string;
  userName: string;
  apsDivision: string;
  apsGroup: string;
  permissions: [];
}