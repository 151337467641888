import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class ReportingRunScheduleService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  downloadFile(badgeNo, run, schedule): any {
    let endPoint = "/data/getSingleRunScheduleReport/" 
      + badgeNo + "/"
      + run + "/"
      + schedule;
    let url = this.authService.getUrlRoot() + endPoint;
    
		return this.unsecureHttp.get(url, {responseType: 'blob'});
  }
 
  getRuns() {
    let url = this.authService.getUrlRoot() + '/data/getRunsByStartEndDates/1-1-2000/1-1-2040';
    
    return this.unsecureHttp.get(url);
  }
    
   
  getBeamlinesForRun(run, runType) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForRun/" + run + "/" + runType;
    
    return this.unsecureHttp.get(url);
  }
  
     
  getBeamlines() {
    let url = this.authService.getUrlRoot() + "/data/getBeamlines";
    
    return this.unsecureHttp.get(url);
  }

  downloadLongRangeExcel(schedules) {
    let url = this.authService.getUrlRoot() + "/data/longRangeOperationsExcel/" 
      + this.authService.getSessionId();
    console.log(url);
    return this.unsecureHttp.post(url, schedules, {responseType: 'blob'});
  }
} 