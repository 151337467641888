import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class SettingsAuthorizationService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

 
  getAuthorizationGroupRoles(badgeNo, domain, group) {
    let url = this.authService.getUrlRoot() + '/data/getAuthorizationGroupRoles/' 
      + badgeNo + "/" + domain + "/" +  group;
    return this.unsecureHttp.get(url);
  }
  
 
  getGroupScopeRoles() {
    let url = this.authService.getUrlRoot() + '/data/getGroupScopeRoles/'; 
    return this.unsecureHttp.get(url);
  }
   
  getAuthorizationGroups(domain, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getAuthorizationGroups/' + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }
  
  getAuthorizationDomains(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getAuthorizationDomains/' + badgeNo;
    return this.unsecureHttp.get(url);
  }
    
  addAuthorizationGroup(group, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/addAuthorizationGroup/' + badgeNo;
    return this.unsecureHttp.post(url, group);
  }
  
  addAuthorizationGroupMember(member, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/addAuthorizationGroupMember/' + badgeNo;
    return this.unsecureHttp.post(url, member);
  }
  
  deleteAuthorizationGroup(domain, group, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/deleteAuthorizationGroup/' 
      + badgeNo + "/" + domain + "/" + group;
    return this.unsecureHttp.get(url);
  }
  
  deleteAuthorizationGroupMember(domain, group, memberBadgeNo, badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/deleteAuthorizationGroupMember/' 
      + badgeNo + "/" + domain + "/" + group + "/" + memberBadgeNo;
    return this.unsecureHttp.get(url);
  }
  
} 