import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class ViewAllService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  getRuns() {
    let url = this.authService.getUrlRoot() + '/data/getRunsByStartEndDates/1-1-2000/1-1-2040';
    
    return this.unsecureHttp.get(url);
  }
    
   
  getViewAllActivitySummary(badgeNo,
                            run,
                            domain,
                            showEmpty,
                            techniques) {
                              
    let url = this.authService.getUrlRoot() + '/data/getViewAllActivitySummary/'
                              + badgeNo
                              + "/" + run
                              + "/" + domain.replace("/", "slash")
                              + "/" + showEmpty
                              + "/" + (techniques ? techniques : "false");
                              console.log(url);
    return this.unsecureHttp.get(url);
  }

  getBeamlinesForRun(run) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForRun/" + run + "/Beamline";
    return this.unsecureHttp.get(url);
  }

     
  getAllTechniques() {
    let url = this.authService.getUrlRoot() + "/data/getAllTechniques";
    return this.unsecureHttp.get(url);
  }

  getViewAllDomains() {
    let url = this.authService.getUrlRoot() + "/data/getViewAllDomains";
    return this.unsecureHttp.get(url);
  }

} 