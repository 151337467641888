import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reporting-tab',
  templateUrl: './reporting-tab.component.html'
})
  
export class ReportingTabComponent {
  
}
