import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class PreferencesService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  editDomainPreference(badgeNo, preferences) {
    let url = this.authService.getUrlRoot() + '/data/editDomainPreference/' + badgeNo;
    return this.unsecureHttp.post(url, preferences);
  }

  editRunOptInPreference(badgeNo, optIn) {
    console.log(optIn);
    let url = this.authService.getUrlRoot() + '/data/editRunOptInPreference/' 
    + badgeNo + "/"
    + optIn;
    return this.unsecureHttp.post(url, optIn);
  }

  getDomainPreferences(badgeNo, domain) {
    let url = this.authService.getUrlRoot() + '/data/getFullDomainPreferenceList/' 
      + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }

  
  getEmailNotificationDays(badgeNo, domain) {
    let url = this.authService.getUrlRoot() + '/data/getEmailNotificationDays/' 
      + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }

  getDefaultShiftHours(badgeNo, domain) {
    let url = this.authService.getUrlRoot() + '/data/getShiftHoursByDomain/' 
      + badgeNo + "/" + domain;
    return this.unsecureHttp.get(url);
  }
  
  updateESAFEmailNotificationDays(badgeNo, domain, days) {
    let url = this.authService.getUrlRoot() + '/data/updateESAFEmailDaysNotification/' 
      + badgeNo + "/" 
      + domain + "/"
      + days;
    return this.unsecureHttp.get(url);
  }
  
  updateDefaultShiftHours(badgeNo, domain, hours) {
    let url = this.authService.getUrlRoot() + '/data/updateDefaultShiftHours/' 
      + badgeNo + "/" 
      + domain + "/"
      + hours;
    return this.unsecureHttp.get(url);
  }

  getPreferencesDomains(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getDomainsForPreferences/' + badgeNo;
    return this.unsecureHttp.get(url);
  }

  getOptInPreference(badgeNo) {
    let url = this.authService.getUrlRoot() + '/data/getRunChangeOptInPreference/' + badgeNo;
    return this.unsecureHttp.get(url);
  }

} 