import { Component, OnInit } from '@angular/core';
import { Constants } from '../shared/constants';
import { ReportingBeamlineScheduleAuditService } from './reporting-beamline-schedule-audit.service';
import { SelectItem } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'reporting-beamline-schedule-audit',
  templateUrl: './reporting-beamline-schedule-audit.component.html',
})
export class ReportingBeamlineScheduleAuditComponent implements OnInit {

  resultsLoading = true;
  selectedRun;
  runs = [];
  
  constructor(
    private authService: AuthService,
    private reportingService: ReportingBeamlineScheduleAuditService) {
  }

  downloadBeamtimeUsage() {
    this.resultsLoading = true;
    this.reportingService.downloadBeamtimeUsage(
        this.selectedRun).subscribe({
      next: data => {
        let d:string = data;
        while (d.indexOf("\\n") >= 0) {
          d = d.replace("\\n", "\n").replace("\\\"", "\"");
        }
        let blob: any = new Blob([d]);
        this.resultsLoading = false;
        fileSaver.saveAs(blob, "Beamtime Usage Audit Report " + this.selectedRun + ".csv");
      },
      error: error => {
        this.resultsLoading = false;
        if (error.status != 401) {
          alert("Error on downloading Beamtime Usage audit report: \n" + error.error);
        }
        console.log(error);
      }
    });
  }


  populateRuns() {
    let self = this;
    this.runs = [];
    this.reportingService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runs.push({ value: r.RunName, label: r.RunName, run: r });
        });
        this.resultsLoading = false;
        let currentRun = this.getCurrentRun(this.runs);
        console.log(currentRun);
        if (currentRun) {
          self.selectedRun = currentRun.RunName;
        }
      },
      error: error => {
        if (error.status != 401) {        
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun(runs) {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    return currentRun;
  }

  ngOnInit() {

    this.populateRuns();
  }

}
