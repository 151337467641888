import { Component, OnInit } from '@angular/core';
import { Constants } from '../shared/constants';
import { ReportingBeamlineScheduleService } from './reporting-beamline-schedule.service';
import { SelectItem } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CreateScheduleService } from '../createschedule/createschedule.service';
import * as fileSaver from 'file-saver';

interface Book {
  name: string;
  author: string;
}

@Component({
  selector: 'reporting-beamline-schedule',
  templateUrl: './reporting-beamline-schedule.component.html',
})
export class ReportingBeamlineScheduleComponent implements OnInit {

  resultsLoading = true;
  runs = [];
  selectedRunBeamtimeUsage;

  beamlines = [];
  beamline2;

  constructor(
    private authService: AuthService,
    private reportingService: ReportingBeamlineScheduleService,
    private createScheduleService: CreateScheduleService) {
  }

  getBeamlineScheduleReport() {
    this.resultsLoading = true;
    this.reportingService.getBeamlineScheduleReport(this.authService.getBadgeNo(),
        this.selectedRunBeamtimeUsage,
        this.beamline2).subscribe({
      next: data => {
        console.log(data);
        let blob: any = new Blob([data]);
        this.resultsLoading = false;
        fileSaver.saveAs(blob, "calendar " + this.selectedRunBeamtimeUsage +
          " " + this.beamline2 + ".ics");
      },
      error: error => {
        this.resultsLoading = false;
        if (error.status != 401) {
          alert("Error on downloading beamline schedule report: \n" + error.error);
        }
        console.log(error);
      }
    });
  }

  populateRuns() {
    let self = this;
    this.runs = [];
    this.reportingService.getRuns().subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach(function (r) {
          self.runs.push({ value: r.RunName, label: r.RunName, run: r });
        });
        this.resultsLoading = false;
        let currentRun = this.getCurrentRun(self.runs);
        if (currentRun) {
          self.selectedRunBeamtimeUsage = currentRun.RunName;
          this.populateBeamlines();
        }
      },
      error: error => {
        if (error.status != 401) {        
          alert("Error on getting runs: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  
  
  populateBeamlines() {

    let self = this;
    this.beamlines = [];
    let counter = 0;

    this.createScheduleService.getBeamlinesForSuperUser(this.authService.getBadgeNo(), 
      this.selectedRunBeamtimeUsage, "YES").subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        if (json.length == 0) {
          alert("There are no schedules defined for run " + this.selectedRunBeamtimeUsage);
        }
        else {
          let options;

            options = json.map((i: any) =>
              ({ label: i.BeamlineVal, value: i.BeamlineVal })
            );
          self.beamlines = options;
          self.beamline2 = self.beamlines[0].value;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamlines: \n" + error.error);
        }
        console.log(error.error);
      }
    });
  }

  getCurrentRun(runs) {
    let urlRoot = this.authService.getUrlRoot();
    let isLocalhost = urlRoot.indexOf("localhost") >= 0;
    let today = new Date();

    let currentRun;

    runs.forEach(r => {
      let start = new Date(r.run.RunStart.split("T")[0]);
      let end = new Date(r.run.RunEnd.split("T")[0]);
      if (today.getTime() > start.getTime() && today.getTime() < end.getTime()) {
        currentRun = r.run;
      }
    });

    return currentRun;
  }

  ngOnInit() {

    this.populateRuns();
  }

}
