import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { LoginComponent } from './login/login.component';
import { ViewEditComponent } from './viewedit/viewedit.component';
import { ViewAllComponent } from './viewall/viewall.component';
import { AdminComponent } from './admin/admin.component';
import { CreateScheduleComponent } from './createschedule/createschedule.component';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';
import { ReportingTabComponent } from './reporting-tab/reporting-tab.component';


const routes: Routes = [
  { path: 'overview', component: OverviewComponent },
  { path: 'login', component: LoginComponent },
  { path: '', component: ViewEditComponent },
  { path: 'viewedit', component: ViewEditComponent },
  { path: 'viewedit/:run/:beamline', component: ViewEditComponent },
  { path: 'viewall', component: ViewAllComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'createschedule', component: CreateScheduleComponent },
  { path: 'createschedule/:run/:beamline', component: CreateScheduleComponent },
  { path: 'settings', component: SettingsTabComponent },
  { path: 'reporting', component: ReportingTabComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
