import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class CalendarService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }
  
   
  getBeamlineStations(beamline) {
    beamline = beamline.replace(",", "comma");
    let url = this.authService.getUrlRoot() + '/data/getBeamlineStations/'
       + beamline;
    return this.unsecureHttp.get(url);
  }
   
  moveActivity(activityId, badgeNo, startDate, endDate) {
    let url = this.authService.getUrlRoot() + '/data/changeActivityDates/'
       + activityId
       + "/" + badgeNo
       + "/" + startDate
       + "/" + endDate;
    return this.unsecureHttp.get(url);
  }
 
  getActivityEmailDetails(activityId) {
    let url = this.authService.getUrlRoot() + '/data/getActivityEmailDetails/'
       + activityId;
    return this.unsecureHttp.get(url);
  }
  
  updateUPSScheduledShifts(body) {
    let url = this.authService.getUrlRoot() + '/data/updateScheduledShifts';
    return this.unsecureHttp.post(url, body);
  }

  sendUPSErrorEmail(body) {
    let url = this.authService.getUrlRoot() + '/data/sendUpsErrorEmail';
    return this.unsecureHttp.post(url, body);
  }

  deleteScheduledBeamtimeActivity(badgeNo, activityId) {
    let url = this.authService.getUrlRoot() + '/data/deleteActivity/'
    + badgeNo + "/" + activityId;
    return this.unsecureHttp.get(url);
  }
/*
  deleteActivity(activityId, isRunScheduleActivity, run?, scheduleName?, badgeNo?) {
    let url;
    if (isRunScheduleActivity) {
      url = this.authService.getUrlRoot() + '/data/deleteRunScheduleActivity/'
       + activityId
        + "/" + run
        + "/" + scheduleName
        + "/" + badgeNo;
    } else {
      url = this.authService.getUrlRoot() + '/data/deleteActivity/'
       + activityId + "/" + badgeNo;
    }
    return this.unsecureHttp.get(url);
  }
  */
  getExperimenterEmailsFromETR(badgeNo, beamline) {
    let url = this.authService.getUrlRoot() + '/data/getExperimenterEmailsFromETR/'
       + badgeNo + "/" + beamline;
    return this.unsecureHttp.get(url);
  }

  getActivityItemVisibilityPolicy(badgeNo, beamline) {
    let url = this.authService.getUrlRoot() + '/data/getActivityItemVisibilityPolicy/'
       + badgeNo + "/" + beamline;
    return this.unsecureHttp.get(url);
  }
      
  getTestEmailDetails(badgeNo, activityId) {
    let url = this.authService.getUrlRoot() + '/data/getTestEmailDetails/'
       + badgeNo + "/" + activityId;
    return this.unsecureHttp.get(url);
  }

  enqueTestEmails(badgeNo, testEmailDetails) {
    let url = this.authService.getUrlRoot() + '/data/enqueTestEmail/'
       + badgeNo;
    return this.unsecureHttp.post(url, testEmailDetails);
  }

  checkUserActivityEditPrivilege(badgeNo, beamline, scheduleType) {
    let url = this.authService.getUrlRoot() + '/data/checkUserActivityEditPrivilege/'
       + badgeNo
       + "/" + beamline
      + "/" + scheduleType;
    
    return this.unsecureHttp.get(url);
  }
  
  getDefaultEmailForBeamline(beamline) {
    let url = this.authService.getUrlRoot() + '/data/getDefaultEmailForBeamline/'
       + beamline;
    return this.unsecureHttp.get(url);
  }
  
  getDefaultColors() {
    let url = this.authService.getUrlRoot() + '/data/getDefaultColors';
    return this.unsecureHttp.get(url);
  }

  getDefaultColorForBTR(badgeNo, BTR) {
    let url = this.authService.getUrlRoot() + '/data/getDefaultColorForBTR/'
    + badgeNo
    + "/" + BTR;
    return this.unsecureHttp.get(url);
  }

  getPeopleInformation(searchTerm) {
    let url = this.authService.getUrlRoot() + '/data/getPeopleInformation/'
       + searchTerm;
    return this.unsecureHttp.get(url);
  }
  
   
  saveRunScheduleActivity(activity, badgeNo) {
    let url;
    
    if (activity.Activity_ID) {
      url = this.authService.getUrlRoot() + '/data/editRunScheduleActivity/' + activity.Activity_ID
        + "/" + badgeNo;
    } else {
      url = this.authService.getUrlRoot() + '/data/addRunScheduleActivity/' + badgeNo;      
    }
    return this.unsecureHttp.post(url, activity);
  }
  
  getBeamtimeRequestsByBeamlineRun(badgeNo, beamline, run) {
    let url = this.authService.getUrlRoot() + '/data/getBeamtimeRequestsByBeamlineRun/'
      + badgeNo
       + "/" + beamline
       + "/" + run;
    return this.unsecureHttp.get(url);
  }  

    
  getSystemActivityTypes() {
    let url = this.authService.getUrlRoot() + '/data/getSystemActivityTypes';
    return this.unsecureHttp.get(url);
  }

  getSystemResources(activityName) {
    activityName = activityName.replace("/", "slash");
    let url = this.authService.getUrlRoot() + '/data/getSystemResources/'
      + activityName;
    return this.unsecureHttp.get(url);
  }
  
  getRunOpenDate(run) {
    let url = this.authService.getUrlRoot() + '/data/getRunOpenDate/' + run;
    return this.unsecureHttp.get(url);
  }

  convertOracleDateToHackedUpFormat(date) {
    //2021-04-02T08:00:00
    let datePart = date.split("T")[0];
    let year = datePart.split("-")[0];
    let month = datePart.split("-")[1];
    let day = datePart.split("-")[2];

    let time = date.split("T")[1];
    let hour = time.split(":")[0];
    let minsec = ":" + time.split(":")[1] + ":" + time.split(":")[2];

    time = hour <= 12 ? time + " am" : (hour - 12) + minsec + " pm";

    return month + "/" + day + "/" + year + " " + time;
  }

  saveActivity(activity, badgeNo) {
    let url;
    if (!activity.Staff_Support_Badge) {
      activity.Staff_Support_Badge = badgeNo;
    }
    
    activity.ScheduleType = "Beamline";
    activity.System_Activity_Flag = "0";
    activity.ActivityName = "Activity Name"; // activity.BTR;
    activity.Utilization = "1";
    activity.Time_Unused = "0";
    if (!activity.ESAF_ID) {
       activity.ESAF_ID = "";
    }
    if (!activity.Activity_Message_Config_ID) {
      activity.Activity_Message_Config_ID = "null";
    }
    if (!activity.Activity_Comment) {
      activity.Activity_Comment = "";
    }
    activity.Parent_Activity_ID = "null";
    activity.Client_FK_ID = "null";
    activity.Logged_In_Badge = badgeNo;
    activity.Hex_Display_Color = activity.Hex_Display_Color.replace("#", "").trim();
    activity.Title = "title";

    //activity.StartDate = this.convertOracleDateToHackedUpFormat(activity.StartDate);
    console.log(activity.EndDate);
    //activity.EndDate = this.convertOracleDateToHackedUpFormat(activity.EndDate);
    console.log("Saving:");
    console.log(activity);
    
    if (activity.Activity_ID) {
      url = this.authService.getUrlRoot() + '/data/saveExistingActivity/' 
        + activity.Activity_ID
        + "/" + badgeNo;
    } else {
      url = this.authService.getUrlRoot() + '/data/saveNewActivity/' + badgeNo;      
    }
    return this.unsecureHttp.post(url, activity);
  }
     
  saveActivityEmailDetail(activityEmailDetail, activityId, badgeNo) {
    let url;
    if (activityEmailDetail.Activity_ID) {
       url = this.authService.getUrlRoot() + '/data/saveExistingActivityEmailDetail/' 
       + activityId +
       "/" + badgeNo;
    } else {
      activityEmailDetail.Activity_ID = activityId;
       url = this.authService.getUrlRoot() + '/data/saveNewActivityEmailDetail/' + activityId
       + "/" + badgeNo;
      
    }
    
    return this.unsecureHttp.post(url, activityEmailDetail);
  }
    
  getRunDetail(run) {
    let url = this.authService.getUrlRoot() + '/data/getRunDetails/' + run;
    
    return this.unsecureHttp.get(url);
  }

  getEsafLink() {
    let url = this.authService.getUrlRoot() + '/data/getEsafLink';
    return this.unsecureHttp.get(url);
  }
}