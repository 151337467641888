import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class AdminService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

 
  getAdminMessages(activeOnly) {
    let url;
    
    if (activeOnly) {
      url = this.authService.getUrlRoot() + '/data/getActiveAdminMessages';
    } else {
      url = this.authService.getUrlRoot() + '/data/getAllAdminMessages';
    }
    
    return this.unsecureHttp.get(url);
  }
  
  deleteMessage(messageId, badgeNumber) {
    let url = this.authService.getUrlRoot() + '/data/deleteAdminMessage/'  + messageId + "/" + badgeNumber;
    return this.unsecureHttp.get(url);
  }
  
  updateMessage(message, badgeNumber) {
    let url = this.authService.getUrlRoot() + '/data/editAdminMessage/'  + message.Message_ID + "/" + badgeNumber;
    return this.unsecureHttp.post(url, message);  
  }
  
  getAllAdminMessages() {
    let url = this.authService.getUrlRoot() + '/data/getAllAdminMessages';
    return this.unsecureHttp.get(url);
  }
  
  addAdminMessage(message, badgeNumber) {
    let url = this.authService.getUrlRoot() + '/data/addAdminMessage/' + badgeNumber;
    return this.unsecureHttp.post(url, message);  
  }
} 