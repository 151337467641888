import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class ReportingBeamlineScheduleService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

  downloadFile(badgeNo, run, schedule): any {
    let endPoint = "/data/getSingleRunScheduleReport/" 
      + badgeNo + "/"
      + run + "/"
      + schedule;
    let url = this.authService.getUrlRoot() + endPoint;
    
		return this.unsecureHttp.get(url, {responseType: 'blob'});
  }
 
  getRuns() {
    let url = this.authService.getUrlRoot() + '/data/getRunsByStartEndDates/1-1-2000/1-1-2040';
    
    return this.unsecureHttp.get(url);
  }
    
   
  getBeamlinesForRun(run) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlinesForRun/" + run + "/Beamline";
    
    return this.unsecureHttp.get(url);
  }

  getBeamlineScheduleReport(badgeNo, run, beamline) {
    let url = this.authService.getUrlRoot() + "/data/getBeamlineScheduleReport/" 
      + badgeNo 
      + "/" + run
      + "/" + beamline;
      console.log(url);
 		return this.unsecureHttp.get(url, {responseType: 'blob'});
  }
} 