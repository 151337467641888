import { HttpInterceptor} from '@angular/common/http';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import  { throwError } from 'rxjs';

@Injectable()
export class OverviewService {

  constructor(
    private unsecureHttp: HttpClient,
    private authService: AuthService) {
  }

 
  getActiveAdminMessages() {
    let url = this.authService.getUrlRoot() + '/data/getActiveAdminMessages';
    return this.unsecureHttp.get(url);
  }
  

} 