import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, DoCheck, EventEmitter, ViewChild} from '@angular/core';
import { CalendarService } from './calendar.service';
import {TreeNode, ConfirmationService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { Message, TreeTable } from 'primeng/primeng';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, OnChanges {

  month: number;
  weekStart: Date;
  year: number;
  @Input() width;
  oneDayViewDate: Date;
  @Input() events = [];
  @Input() run;
  @Input() beamline;
  @Input() scheduleType;
  @Output() refreshFromDatabase = new EventEmitter();
  @Output() getScheduleGuidance = new EventEmitter<any>();
  @Input() userCanAddActivityToThisBeamline = false;
  @Input() useCurrentMonth = true;
  @Input() defaultShiftHours;

  runDetails;
  oldRun;
  viewingActivity = false;
  view;
  oneDay = 86400000;
  oneHour = 3600000;
  fiveMins = 300000;
  timeZone = " 00:00:00 +00:00";
  weeks = [];
  columns = [];
  eventBeingShown;
  editingActivity;
  beamlineRequests = [];
  flatBeamlineRequests = [];
  selectedBeamlineRequest: TreeNode ;
  beamlineStations = [];
  person = {};
  staffSearchResults;
  editActivityModes;
  editActivityMode;
  activityEmailDetails;
  emailPerson;
  staffSupportPerson;
  editableEmailAddress;
  selectedEmailRecipients;
  otherActivities = [];
  selectedOtherActivities = [];
  activityItemVisibilityPolicy = [{}];
  activityItemEditability = {};
  viewingRunScheduleActivity = false;
  editingRunScheduleActivity = false;
  msgs: Message[];
  selectedRunStart: Date;
  selectedRunEnd: Date;
  esafLink: Object;
  isDev: boolean;
  initialDurationOfActivity;
  initialShiftsInActivity;
  showingTestEmailsDialog = false;
  testEmailDetails = {
      Activity_ID: 0,
      TestActivityScheduled: false,
      TestESAFReminder: false,
      TestExperimentStart: false,
      TestExperimentEnd: false,
      TestPublicationReminder: false,
      TestSendToEmail: ""
    }

  activityColors = [];
  displayColor = { ColorName: "white", HexColorCode: "FFFFFF"};
  @ViewChild("tt", {static: false}) proposalTable: TreeTable;
  groupFilter;
  PIfilter;
  reqFilter;
  schFilter;
  blFilter;
  pastRunOpenDate: boolean;
  todayBackground = "#007ad9b0";
  subs = new Subscription();
  systemActivityTypes = [];
  systemResources = [];

  constructor(private calendarService: CalendarService, 
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private dragulaService: DragulaService) {
    
   this.subs.add(this.dragulaService.drop()
      .subscribe(({ name, el, target, source, sibling }) => {
        let diff = parseInt(target.id) - parseInt(source.id);
        this.eventBeingShown = this.events.find( e => { return e.eventFromApi.Activity_ID == el.id });
        this.eventBeingShown.startAsDateObject = new Date(this.eventBeingShown.startAsDateObject.getTime() + diff);
        this.eventBeingShown.endAsDateObject = new Date(this.eventBeingShown.endAsDateObject.getTime() + diff);

        this.moveActivity(el.id, 
          this.eventBeingShown.startAsDateObject, 
          this.eventBeingShown.endAsDateObject);
        /*  
        console.log(this.eventBeingShown.startAsDateObject);
        this.eventBeingShown.startAsDateObject = new Date(this.eventBeingShown.startAsDateObject.getTime() + diff);
        this.eventBeingShown.endAsDateObject = new Date(this.eventBeingShown.endAsDateObject.getTime() + diff);
        console.log(this.eventBeingShown.startAsDateObject);
        // replace with call to new API to change dates
        this.eventBeingShown.startDate = this.formatDateForSaving(this.eventBeingShown.startAsDateObject);
        this.eventBeingShown.endDate = this.formatDateForSaving(this.eventBeingShown.endAsDateObject);
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Dates changed', detail: "Activity succesfully changed:\n " + this.formatFullStartAndEndDates(this.eventBeingShown)});

        this.updateCalendar(0);
          */
      })
    );
  }


  moveActivity(activityId, startDate, endDate) {
    let newStartDateMillis = new Date(startDate).getTime();
    let newEndDateMillis = new Date(endDate).getTime();
    let runStartMillis = this.selectedRunStart.getTime();
    let runEndMillis = this.selectedRunEnd.getTime();
    if (newStartDateMillis < runStartMillis || newEndDateMillis > runEndMillis) {
      alert("The activity cannot be moved outside the run");
      this.refreshFromDatabase.emit();
      return;
    }

    this.eventBeingShown.startDate = this.formatDateForSaving(startDate);
    this.eventBeingShown.endDate = this.formatDateForSaving(endDate);
    this.updateCalendar(0);

    this.calendarService.moveActivity(activityId, 
      this.authService.getBadgeNo(), 
      this.formatDateForSaving(startDate), 
      this.formatDateForSaving(endDate)).subscribe({
      next: data => {
        this.msgs = [];
        this.msgs.push({ severity: "success", summary: 'Dates changed', detail: "Activity succesfully moved.<br/>This may result in new emails being sent."});
        this.refreshFromDatabase.emit();
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on changing activity dates: \n" + error.error);
        }
        console.log(error.error); 
        this.refreshFromDatabase.emit();
     
      }
    });
  }

  showTestEmailsDialog() {
    this.getTestEmailDetails();
    this.showingTestEmailsDialog = true;
  }

  hideTestEmailsDialog() {
    this.showingTestEmailsDialog = false;
  }

  checkUncheckAll(check) {
    this.activityEmailDetails.Enable_Activity_Scheduled = check;
    this.activityEmailDetails.Enable_ESAF_Reminder = check;
    this.activityEmailDetails.Enable_EXP_Reminder = check;
    this.activityEmailDetails.Enable_End_EXP_Reminder = check;
    this.activityEmailDetails.Enable_PUB_Reminder = check;
  }

  getDefaultColors() {
      let temp = [];
      this.calendarService.getDefaultColors().subscribe({
      next: data => {
        this.activityColors = JSON.parse(data['body']);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting default activity colors: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }
  
  calcShifts() { 
    if (this.eventBeingShown && this.eventBeingShown.startAsDateObject && 
      this.eventBeingShown.endAsDateObject) {
        let currentDuration = this.eventBeingShown.endAsDateObject.getTime() -
        this.eventBeingShown.startAsDateObject.getTime();
        let hours = Math.abs(currentDuration  / 3600000);
        return Math.ceil(hours / 8);
      }
    }

  setPastRunOpenDate() {
    this.runDetails.RunStart = this.runDetails.RunStart.replaceAll("T", " ");
    this.runDetails.RunOpenDate = this.runDetails.RunOpenDate.replaceAll("T", " ");
    let runOpenDate = new Date(this.runDetails.RunOpenDate);
    let now = new Date();
    this.pastRunOpenDate = now.getTime() > runOpenDate.getTime();
  }

  getDefaultEmailForBeamline() {
      let temp = [];
      this.calendarService.getDefaultEmailForBeamline(this.beamline).subscribe({
      next: data => {
        let addy = JSON.parse(data['body']);
        this.activityEmailDetails['From_Email_Address'] = addy;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting default email address for beamline: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  getTestEmailDetails() {
      let temp = [];
      this.calendarService.getTestEmailDetails(this.authService.getBadgeNo(),
        this.eventBeingShown.eventFromApi.Activity_ID).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.testEmailDetails = json;
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting test email details: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

    sendTestEmails() {
      this.hideTestEmailsDialog();
      let temp = [];
      this.calendarService.enqueTestEmails(this.authService.getBadgeNo(),
        this.testEmailDetails).subscribe({
      next: data => {
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on sending test emails: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  populateStaffSupportFields() {
    if (this.staffSupportPerson) {
      this.eventBeingShown.eventFromApi.Staff_Support_Name = this.staffSupportPerson.Name;
      this.eventBeingShown.eventFromApi.Staff_Support_Badge = this.staffSupportPerson.Badge_No;
      this.eventBeingShown.eventFromApi.Staff_Support_Email = this.staffSupportPerson.Email;
    }
  }
  
  getTimeDelta() {
    if (this.eventBeingShown && this.eventBeingShown.startAsDateObject && 
      this.eventBeingShown.endAsDateObject) {
        let currentDuration = this.eventBeingShown.endAsDateObject.getTime() -
        this.eventBeingShown.startAsDateObject.getTime();
        return Math.abs((currentDuration - this.initialDurationOfActivity) / 3600000) + " hours";
      }
      else {
        return "";
      }
  }
  
  getTimeDuration() {
    if (this.eventBeingShown && this.eventBeingShown.startAsDateObject && 
      this.eventBeingShown.endAsDateObject) {
        let currentDuration = this.eventBeingShown.endAsDateObject.getTime() -
        this.eventBeingShown.startAsDateObject.getTime();
        return Math.abs(currentDuration  / 3600000) + " hours ("
          + this.calcShifts() + " shifts)";
      }
      else {
        return "";
      }
  }

  newActivity(startTime) {
    console.log("in newActivity");
    let defaultStartDate;
    if (!startTime) {
      defaultStartDate = this.selectedRunStart;
    } else {
      defaultStartDate = new Date(startTime);
      defaultStartDate.setHours(8);
    }
    let shiftLengthMillis = this.defaultShiftHours * this.oneHour;
    let timezoneOffsetMillis = defaultStartDate.getTimezoneOffset() * 60 * 1000;
    defaultStartDate = new Date(defaultStartDate.getTime() - timezoneOffsetMillis);
    let defaultEndDate = new Date(defaultStartDate.getTime() + shiftLengthMillis);
    
    let startAsDateObject= new Date(defaultStartDate.getTime() + timezoneOffsetMillis);// new Date(this.eventBeingShown.start + timezoneOffsetMillis ); // = new Date(this.eventBeingShown.start);
    let endAsDateObject = new Date(defaultStartDate.getTime() + timezoneOffsetMillis + shiftLengthMillis);
    this.setPastRunOpenDate();
    
    if (this.scheduleType == "Beamline") {
      this.eventBeingShown = { 
        start: defaultStartDate.getTime(),
        end: defaultEndDate.getTime(),
        startAsDateObject: startAsDateObject,
        endAsDateObject: endAsDateObject,
        eventFromApi: {
          "Run": this.run,
          "Beamline": this.beamline,
          "Category": "Beamtime Request",
          "Hex_Display_Color": "FFFFFF"
        } 
      };
console.log(this.eventBeingShown);
      this.editActivityMode = "Activity";
      this.initialShiftsInActivity = 0;
      this.editActivity();
    } else { // Run activity
      this.pastRunOpenDate = true;
      this.eventBeingShown = { 
        start: defaultStartDate.getTime(),
        end: defaultEndDate.getTime(),
        startAsDateObject: startAsDateObject,

        eventFromApi: {
          Resource: null,
          Run: this.run,
          ScheduleName: this.beamline
        } 
      };
      this.staffSupportPerson = null;
      this.editRunScheduleActivity();      
    }
  }

  updateUPSScheduledShifts(responseFromSavingActivity, beamline) {
    if (responseFromSavingActivity.ETR) {
      // saved a new activity or deleted one
      this.updateUPSScheduledShiftsAPICall(
        responseFromSavingActivity.ETR,
        responseFromSavingActivity.TotalEtrShifts,
        beamline
      )
    } else if (responseFromSavingActivity.CurrentETR) {
      // edited an activity
      this.updateUPSScheduledShiftsAPICall(
        responseFromSavingActivity.CurrentETR,
        responseFromSavingActivity.TotalCurrentEtrShifts,
        beamline
      )

      if (responseFromSavingActivity.ETRupdatedFlag == "y") {
        // edited an activity and selected a new BTR
        this.updateUPSScheduledShiftsAPICall(
          responseFromSavingActivity.PreviousETR,
          responseFromSavingActivity.TotalPreviousEtrShifts,
          beamline
        )
      }
    }
  }

  updateUPSScheduledShiftsAPICall(BTR, numberOfShifts, beamline) {

    let body = {
      beamline_id: beamline,
      beamtime_id: new String(BTR),
      shift: new String(numberOfShifts)
    };

    this.calendarService.updateUPSScheduledShifts(body).subscribe({
      next: data => {
        console.log("Successfully updated UPS scheduled shifts");
        console.log(data);
      },
      error: error => {
        alert("Error on updating UPS scheduled shifts.\nAn email has been sent informing the user office of this error: \n\n" + error.error);
        console.log(error.error);  
        this.sendUPSUpdateScheduledShiftsErrorEmail(numberOfShifts, error.error);    
      }
    });
  }

  sendUPSGetBeamtimeRequestsErrorEmail(beamline, run, errorMessage) {

    let body = "Hello,<p/><p/>This is from the beamline scheduling system to inform you that an error occurred<p/>"
    + "when getting the beamtime requests from the Universal Proposal System API "
    + "for beamline " + beamline + " and run " + run + ".<p/>"
    + "<p/><p/>Error message:<p/>" + errorMessage;

    this.calendarService.sendUPSErrorEmail(body).subscribe({
      next: data => {
      },
      error: error => {
        alert("Error on sending UPS get beamtime requests error notification: \n" + error.error);
        console.log(error.error);      }
    });
  }

    sendUPSUpdateScheduledShiftsErrorEmail(numberOfShifts, errorMessage) {

    let system = this.isDev ? "DEV" : "PROD";

    let body = "Hello,<p/><p/>This is from the " + system + " beamline scheduling system to inform you that an error occurred<p/>"
    + "when making the updateScheduledShifts API call to UPS to let UPS know "
    + "that shifts have been scheduled or removed. Details below:<p/>"
    + "<p/>User using scheduling system: " + this.authService.getUser().fullName
    + "<p/>Beamline: " + this.eventBeingShown.eventFromApi.Beamline
    + "<p/>PI: " + this.eventBeingShown.eventFromApi.PI_Last_Name
    + "<p/>Start: " + this.eventBeingShown.eventFromApi.StartDate
    + "<p/>End: " + this.eventBeingShown.eventFromApi.EndDate
    + "<p/>Number of shifts: " + numberOfShifts
    + "<p/>ETR: " + this.eventBeingShown.eventFromApi.BTR
    + "<p/><p/>Error message:<p/>" + errorMessage;

    this.calendarService.sendUPSErrorEmail(body).subscribe({
      next: data => {
      },
      error: error => {
        alert("Error on sending UPS scheduled shifts error notification: \n" + error.error);
        console.log(error.error);      }
    });
  }
  
  changeRun() {
    let self = this;
    this.calendarService.getRunDetail(this.run).subscribe({
      next: data => {
        self.runDetails = JSON.parse(data['body']);
        this.selectedRunStart = new Date(self.runDetails.RunStart.replace("T", " "));
        this.selectedRunEnd = new Date(self.runDetails.RunEnd.replace("T", " "));
        if (this.useCurrentMonth) {
          this.useCurrentMonth = false;
        } else {
          this.month = this.selectedRunStart.getMonth() + 1;
          this.year = this.selectedRunStart.getFullYear();
        }
        self.switchMonthView(null);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting run details: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }
    
  getActivityEmailDetails() {
    let self = this;
    
    this.calendarService.getActivityEmailDetails(this.eventBeingShown.eventFromApi.Activity_ID).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        this.activityEmailDetails = json;
      },
      error: error => {
        console.log("Error getting email config. It may not be defined yet for this activity.");
        console.log(error);
      }
    });
  } 
  
  removeFromEmailRecipients() {
    if (this.activityEmailDetails.Activity_Recipient) {
      let i = this.activityEmailDetails.Activity_Recipient.indexOf(this.selectedEmailRecipients);
      this.selectedEmailRecipients = null;
      this.activityEmailDetails.Activity_Recipient.splice(i, 1);
      this.activityEmailDetails.Activity_Recipient = JSON.parse(JSON.stringify(this.activityEmailDetails.Activity_Recipient));
    }
  }
  
  staffSearch(event) {
    let self = this;
    
    if (event.query.length <= 3) {
      return;
    } else {
      this.calendarService.getPeopleInformation(event.query).subscribe({
        next: data => {
          self.staffSearchResults = JSON.parse(data['body']);
        },
        error: error => {
          if (error.status != 401) {
            alert("Error on getting people details: \n" + error.error);
          }
          console.log(error.error);        }
      });
    }
  } 
   
  compareByCustom_Group(a, b) {
    return a.Custom_Group.localeCompare(b.Custom_Group);
  }

  populateBeamlineRequests() {
    let self = this;
    this.beamlineRequests = [];
    this.calendarService.getBeamtimeRequestsByBeamlineRun(this.authService.getBadgeNo(),
       this.beamline, 
       this.run).subscribe({
      next: data => {
        let lastGroup = "last";
        
        let json = JSON.parse(data['body'])['result']['BeamtimeRequests']
        this.flatBeamlineRequests = json;
        json.sort(this.compareByCustom_Group);

        let groupRow;
        let self = this;
        let childToSelect;

        
        json.forEach(function(r) {

          if (r.Custom_Group != lastGroup) {
            groupRow = {
              data: {
                group: r.Custom_Group
              },
              expanded: true,
              children: []
            };
            self.beamlineRequests.push( groupRow );
            lastGroup = r.Custom_Group;
          } 
          let child = {
              data: {
                group: r.GUP_ID,
                PI: r.PI_Last_Name,
                req: r.Requested_Shifts + "/" + r.Granted_Shifts + " shifts",
                sch: r.Beamline_Scheduled_shifts + " shifts",
                bl: r.Beamline_Rank,
                Beamtime_ID: r.Beamtime_ID,
                request: r
              }};
          groupRow.children.push(child);
          if (r.Beamtime_ID == self.eventBeingShown.eventFromApi.BTR) {
            childToSelect = child;
          }
        });
        setTimeout(() => { self.selectedBeamlineRequest = childToSelect; }, 1000);

        self.beamlineRequests = JSON.parse(JSON.stringify(self.beamlineRequests));
        //this.setActivityDetailsFromBeamlineRequest();
      },
      error: error => {
        if (error.status != 401) {
          this.sendUPSGetBeamtimeRequestsErrorEmail(this.beamline, this.run, error);
          alert("Error on getting beamtime requests. A notification email has been sent to mis_mgrs. \n\n" + error.error);
        }
        console.log(error.error);      }
    });
  }
  
  getDefaultColorForBTR(BTR) {
      let temp = [];
      this.calendarService.getDefaultColorForBTR(this.authService.getBadgeNo(),
      BTR).subscribe({
      next: data => {
        this.displayColor = JSON.parse(data['body'])[0];
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting default color for BTR: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  populateEventFromRequest(beamlineRequest) {
    console.log(beamlineRequest.request);
    this.eventBeingShown.eventFromApi.Proposal = beamlineRequest.request.Type_Description
    + " "
    + beamlineRequest.request.GUP_ID;
    
    this.getDefaultColorForBTR(beamlineRequest.request.Beamtime_ID);
    if (this.eventBeingShown.eventFromApi.BTR) {
      this.eventBeingShown.eventFromApi['Old_BTR'] = this.eventBeingShown.eventFromApi.BTR;
    }
    this.eventBeingShown.eventFromApi.BTR = beamlineRequest.request.Beamtime_ID;
    
    if (this.eventBeingShown.eventFromApi.Beamline) {
      this.eventBeingShown.eventFromApi.Old_Beamline_Name = this.eventBeingShown.eventFromApi.Beamline;
    }
    //this.eventBeingShown.eventFromApi.Beamline = beamlineRequest.request.Beamline;
    
    this.eventBeingShown.eventFromApi.PI_First_Name = beamlineRequest.request.PI_First_Name;
    this.eventBeingShown.eventFromApi.PI_Last_Name = beamlineRequest.request.PI_Last_Name;
    this.eventBeingShown.eventFromApi.Title = beamlineRequest.request.Proposal_Title;
    if (this.eventBeingShown.eventFromApi.Title.length > 65) {
      this.eventBeingShown.eventFromApi.Title = this.eventBeingShown.eventFromApi.Title.substr(0, 65) + "...";
    } 
    this.eventBeingShown.eventFromApi.Institution = beamlineRequest.request.PI_Institution;
    this.eventBeingShown.eventFromApi.Status = beamlineRequest.request.Status;
    this.eventBeingShown.eventFromApi.Activity_Type = beamlineRequest.request.Type_Description;
    this.populateGUPEmailsForProposal(beamlineRequest.request.Beamtime_ID);
    setTimeout(() => {     console.log(this.selectedBeamlineRequest); }, 1000);
  }
  
  clearBeamtimeRequestFields() {
    this.eventBeingShown.eventFromApi.Proposal = "";
    this.eventBeingShown.eventFromApi['Old_BTR'] = "";
    this.eventBeingShown.eventFromApi.BTR = "";
    this.eventBeingShown.eventFromApi.PI_First_Name = "";
    this.eventBeingShown.eventFromApi.PI_Last_Name = "";
    this.eventBeingShown.eventFromApi.Title = "";
    this.eventBeingShown.eventFromApi.Institution = "";
    this.eventBeingShown.eventFromApi.Status = "";
  }

  editActivity() {
    console.log(this.eventBeingShown);
    this.initialDurationOfActivity = this.eventBeingShown.endAsDateObject.getTime() -
      this.eventBeingShown.startAsDateObject.getTime();
    this.viewingActivity = false;
    this.editingActivity = true;
    this.editActivityMode = "Activity";
    this.staffSupportPerson = null;
    this.populateBeamlineRequests();
    /* if (this.proposalTable && this.proposalTable.filters) {
      if (this.proposalTable.filters.PI) {
        this.proposalTable.filters.PI.value = "";
      }
      if (this.proposalTable.filters.group) {
        console.log(this.proposalTable.filters.group);
        this.proposalTable.filters.delete("group");
      }
      if (this.proposalTable.filters.req) {
        this.proposalTable.filters.req.value = "";
      }
      if (this.proposalTable.filters.sch) {
        this.proposalTable.filters.sch.value = "";
      }
    } */
    this.groupFilter = "";
    this.PIfilter = "";
    this.schFilter = "";
    this.reqFilter = "";
    this.blFilter = "";
    if (this.proposalTable) {
      this.proposalTable.reset();
    }
    let displayColor = this.activityColors.find( c => { return c.HexColorCode == this.eventBeingShown.eventFromApi.Hex_Display_Color; });
    if (displayColor) {
      this.displayColor = displayColor;
    } else {
      this.displayColor = { HexColorCode: this.eventBeingShown.eventFromApi.Hex_Display_Color, ColorName: "" };
    }
    this.getBeamlineStations();
    if (this.eventBeingShown.eventFromApi.Activity_ID) {
      this.getActivityEmailDetails();   
    } else {
      this.activityEmailDetails = {Activity_Recipient:[], Hold: true};
      this.getDefaultEmailForBeamline();
    }
  }
  
  setActivityDetailsFromBeamlineRequest() {
    if (this.eventBeingShown.eventFromApi.BTR) {
      let br = this.flatBeamlineRequests.filter( b => b.Beamtime_ID === this.eventBeingShown.eventFromApi.BTR);
      if (br.length == 0) {
        console.log("No BTR found for ID " + this.eventBeingShown.eventFromApi.BTR);
      } else {
        this.eventBeingShown.eventFromApi.Title = br[0].Proposal_Title;
        console.log("Length: " + this.eventBeingShown.eventFromApi.Title.length);
        if (this.eventBeingShown.eventFromApi.Title.length > 65) {
          console.log(this.eventBeingShown.eventFromApi.Title.length );
          this.eventBeingShown.eventFromApi.Title = this.eventBeingShown.eventFromApi.Title.substr(0, 65) + "...";
        } 
        this.eventBeingShown.eventFromApi.Proposal = br[0].ProposalType;
      }
    }
    else {
      console.log("No BTR ID set.");
    }
  }
    
  getSystemActivityTypes() {
      this.calendarService.getSystemActivityTypes().subscribe({
      next: data => {
          this.systemActivityTypes = JSON.parse(data['body']);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting system activity types: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  getSystemResources(activityName) {
      this.calendarService.getSystemResources(activityName).subscribe({
      next: data => {
          this.systemResources = JSON.parse(data['body']);
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting system resources: \n" + error.error);
        }
        console.log(error.error);      
      }
    });
  }

  getActivityItemVisibilityPolicy() {
      let temp = [];
      this.calendarService.getActivityItemVisibilityPolicy(this.authService.getBadgeNo(), this.beamline).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.activityItemVisibilityPolicy = json;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting activity visibility policy: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }
  
  
  getESAFLink() {
    let activityId = this.eventBeingShown.eventFromApi.Activity_ID;

    if (this.isDev) {
      return "https://beamtest.aps.anl.gov/pls/apsweb/esaf0004.create_esaf_menu?i_attrib="
        + activityId;
    } else {
      return "https://beam.aps.anl.gov/pls/apsweb/esaf0004.create_esaf_menu?i_attrib="
        + activityId;
    }
  }

  checkUserActivityEditPrivilege() {
      let temp = [];
      this.calendarService.checkUserActivityEditPrivilege(this.authService.getBadgeNo(), this.beamline, this.scheduleType).subscribe({
      next: data => {
        if (data) {
          let json = JSON.parse(data['body']);
          this.activityItemEditability = json;
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting user edit privileges: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }
  
  getBeamlineStations() {
      let temp = [];
      this.calendarService.getBeamlineStations(this.beamline).subscribe({
      next: data => {
        let json = JSON.parse(data['body']);
        json.forEach( s => {
          temp.push(s.StationName);
        });
        this.beamlineStations = temp;
        if (!this.eventBeingShown.eventFromApi.Station) {
          this.eventBeingShown.eventFromApi.Station = this.beamlineStations[0];
        }
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting beamline stations: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }

  populateGUPEmailsForProposal(Beamtime_ID) {

      let temp = [];
      this.activityEmailDetails.Activity_Recipient = [];
      this.calendarService.getExperimenterEmailsFromETR(this.authService.getBadgeNo(),
       Beamtime_ID).subscribe({
      next: data => {
        let json = JSON.parse(data['body'])['result']['data']
        json.forEach( s => {
          this.addEmailAddressToRecipients(s);
        });
      },
      error: error => {
        if (error.status != 401) {
          alert("Error on getting experimenter emails for proposal: \n" + error.error);
        }
        console.log(error.error);      }
    });
  }

  twoDigitNumber(n) {
    return n < 10 ? "0" + n : n;
  }
  
  formatDateForSaving(date) {
    return date.getFullYear()
    + "-" + this.twoDigitNumber(date.getMonth() + 1)
    + "-" + this.twoDigitNumber(date.getDate())
    + "T" + this.twoDigitNumber(date.getHours())
    + ":" + this.twoDigitNumber(date.getMinutes())
    + ":00";
  }
  
  populateEditableEmailAddress() {
    if (this.emailPerson) {
      this.editableEmailAddress = this.emailPerson.Email;
    }
  }
  
  addEmailToRecipients() {
    if (this.editableEmailAddress && this.validateEmail(this.editableEmailAddress)) {
      this.addEmailAddressToRecipients(this.editableEmailAddress);
    } else {
      alert("Field must contain a valid email address");
    }
  }

  addEmailAddressToRecipients(emailAddress) {
          if (this.activityEmailDetails.Activity_Recipient.filter( e => e.Recipient == emailAddress ) == 0) {
        this.activityEmailDetails.Activity_Recipient.push( { Recipient: emailAddress,
                                   Activity_Message_Config_ID: this.activityEmailDetails.Activity_Message_Connfig_ID,
                                   Activity_ID: this.activityEmailDetails.Activity_ID   } );
        this.activityEmailDetails.Activity_Recipient = JSON.parse(JSON.stringify(this.activityEmailDetails.Activity_Recipient));
      }
  }

  dateIsInCurrentRun(date: Date) {
    return date.getTime() >= this.selectedRunStart.getTime() &&
      date.getTime() <= this.selectedRunEnd.getTime();
  }

  validate() {
    if (this.eventBeingShown.eventFromApi.Category == "Beamtime Request") {
      if (!this.eventBeingShown.startAsDateObject) {
        alert("A start time must be entered");
        return false;
      }

      if (!this.selectedBeamlineRequest) {
        alert("A proposal must be selected");
        return false;
      }

      if (this.isInPreviousFY(this.eventBeingShown.startAsDateObject)) {
        alert("Activity must start in the current financial year");
        return false;
      }
      
      if (!this.eventBeingShown.endAsDateObject) {
        alert("An end time must be entered");
        return false;
      }
      
      if (this.eventBeingShown.startAsDateObject > this.eventBeingShown.endAsDateObject) {
        alert("Start time must be before end time");
        return false;
      }

      if (!this.dateIsInCurrentRun(this.eventBeingShown.startAsDateObject)) {
        alert("Start time must be within the selected run");
        return false;
      }

      if (!this.dateIsInCurrentRun(this.eventBeingShown.endAsDateObject)) {
        alert("End time must be within the selected run");
        return false;
      }

      if (!this.eventBeingShown.eventFromApi.Station) {
        alert("A station must be selected");
        return false;
      }
      
    }

     if (this.eventBeingShown.eventFromApi.Category == "Staff Support") {
       if (!this.eventBeingShown.eventFromApi.Staff_Support_Badge) {
         alert("A staff member must be selected");
         return false;
       }
     }

     if (this.eventBeingShown.eventFromApi.Category == "Other") {
       if (!this.eventBeingShown.eventFromApi.Activity_Type) {
         alert("An activity type must be selected");
         return false;
       }
     }

    return true;
  }
  
  deleteActivity(isRunScheduleActivity) {
    this.confirmationService.confirm({
            message: 'Are you sure you want to delete this activity?',
            accept: () => {
              if (isRunScheduleActivity) {
                this.hideRunScheduleActivityDialog()
              } else {
                this.hideViewActivityDialog();
              }
 //             if (this.eventBeingShown.eventFromApi.BTR) {

                this.calendarService.deleteScheduledBeamtimeActivity(
                                        this.authService.getBadgeNo(),
                                          this.eventBeingShown.eventFromApi.Activity_ID).subscribe({
                  next: data => {

                    console.log("Delete activity response: " + data['body']);
                    let response = JSON.parse(JSON.parse(data['body']));
                    this.updateUPSScheduledShifts(response, this.eventBeingShown.eventFromApi.Beamline);
                    this.refreshFromDatabase.emit();
                    this.getScheduleGuidance.emit();
                  },
                  error: error => {
                    if (error.status != 401) {
                      alert("Error on deleting activity: \n" + error.error);
                    }
                    console.log(error.error);                  
                    this.refreshFromDatabase.emit();
                    this.getScheduleGuidance.emit();
                  }
                }); 
/*
              } else {
                this.calendarService.deleteActivity(this.eventBeingShown.eventFromApi.Activity_ID,
                                        isRunScheduleActivity,
                                        this.run,
                                        this.beamline,
                                        this.authService.getBadgeNo()).subscribe({
                  next: data => {
                    console.log("Delete activity response: " + data['body']);
                      this.refreshFromDatabase.emit();
                      this.getScheduleGuidance.emit();
                  },
                  error: error => {
                    if (error.status != 401) {
                      alert("Error on deleting activity: \n" + error.error);
                    }
                    console.log(error.error);                  
                    this.refreshFromDatabase.emit();
                    this.getScheduleGuidance.emit();
                  }
                });            
              } */
           } 
        }); 
        
  }

    saveActivity() {
    if (!this.validate()) {
      return;
    }
    let self = this;
    this.formatEventDatesForSaving();
    if (this.displayColor) {
      this.eventBeingShown.eventFromApi.Hex_Display_Color = this.displayColor.HexColorCode;
    }
    this.cancelEditingActivity();
    this.calendarService.saveActivity(this.eventBeingShown.eventFromApi, 
      this.authService.getBadgeNo()).subscribe({
        next: data => {
          console.log("Save activity response: " + data['body']);
            if (this.eventBeingShown.eventFromApi.Category == "Beamtime Request") {
              let response = JSON.parse(JSON.parse(data['body']));
              let activityId = response.activity_id;
              this.saveActivityEmailConfig(activityId);
              this.msgs = [];
              this.msgs.push({ severity: "success", summary: 'Success', detail: "Saved successfully" });
              this.updateUPSScheduledShifts(response, this.eventBeingShown.eventFromApi.Beamline); 
          }
        this.refreshFromDatabase.emit();
        this.getScheduleGuidance.emit();

      },
      error: error => {
        if (error.status != 401) {
          alert("Error on saving activity: \n" + error.error);
        }
        console.log(error.error);        
        this.refreshFromDatabase.emit();
        this.getScheduleGuidance.emit();
      }
    });
    
  }

  saveActivityEmailConfig(activityId) {
    console.log("Saving activity email details");
    console.log(this.activityEmailDetails);
      this.calendarService.saveActivityEmailDetail(this.activityEmailDetails, 
        activityId,
        this.authService.getBadgeNo()).subscribe({
        next: data => {
          console.log("Save activity email config response: " + data['body']);
        },
        error: error => {
          if (error.status != 401) {
              alert("Error on saving email configuration: \n" + error.error);
          }
          console.log(error.error);        
        }
      });

  }

  formatEventDatesForSaving() {
    //this.eventBeingShown.startAsDateObject= new Date(this.eventBeingShown.startAsDateObject.getTime()); // = new Date(this.eventBeingShown.start);
    //this.eventBeingShown.endAsDateObject = new Date(this.eventBeingShown.endAsDateObject.getTime());
    this.eventBeingShown.eventFromApi.StartDate = this.formatDateForSaving(this.eventBeingShown.startAsDateObject);
    this.eventBeingShown.eventFromApi.EndDate = this.formatDateForSaving(this.eventBeingShown.endAsDateObject);

  }

  removeResource() {
    if (this.eventBeingShown.eventFromApi.Activity_Type != "user operation") {
      this.eventBeingShown.eventFromApi.Resource = "";
    }
    this.getSystemResources(this.eventBeingShown.eventFromApi.Activity_Type);
  }

  saveRunScheduleActivity() {
    
    let self = this;
    this.formatEventDatesForSaving();
    this.cancelEditingRunScheduleActivity();
    console.log("Saving:");
    console.log(JSON.stringify(this.eventBeingShown.eventFromApi));
    this.calendarService.saveRunScheduleActivity(this.eventBeingShown.eventFromApi, this.authService.getBadgeNo()).subscribe({
      next: data => {
        console.log("Save activity response: " + data['body']);
          this.refreshFromDatabase.emit();
          this.getScheduleGuidance.emit();

      },
      error: error => {
        if (error.status != 401) {
          alert("Error on saving activity: \n" + error.error);
        }
        console.log(error.error);        
        this.refreshFromDatabase.emit();
        this.getScheduleGuidance.emit();
      }
    });

  }
  
  cancelEditingActivity() {
    this.editingActivity = false;
    this.refreshFromDatabase.emit();
    this.getScheduleGuidance.emit();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    
    if (!this.year) {
      let today = new Date();
      this.month = today.getMonth() + 1;
      this.year = today.getFullYear();
    }
    
    if (this.run && this.run != this.oldRun) {
      this.oldRun = this.run;
      this.changeRun();
    } else {
      this.redrawCalendar();
    }
    
  }
  
  redrawCalendar() {
    if (!this.view || this.view == "month") {
      this.switchMonthView(null);
    } else if (this.view == "week") {
      this.switchWeekView();
    } else {
      this.switchDayView();
    }
  }
  
  activityBeingEditedStartsInPreviousFY() {
      return this.isInPreviousFY(this.eventBeingShown.startAsDateObject);
  }

  isInSelectedRun(date: Date) {
    if (!this.selectedRunStart) {
      return false;
    }
    return date.getTime() >= this.selectedRunStart.getTime()
      && date.getTime()  <= this.selectedRunEnd.getTime();
  }

  selectedRunIsInPreviousFY() {
    if (!this.runDetails) {
      return false;
    }

    return this.isInPreviousFY(this.selectedRunEnd);
  }

  isInPreviousFY(date: Date) {
    let now = new Date();
    if (date.getFullYear() >= now.getFullYear()) {
      return false;
    }

    if (date.getFullYear() < (now.getFullYear() - 1)) {
      return true;
    }

    // now check month
    if (date.getMonth() + 1 <= 9) {
      return true;
    }

    return false;
  }
  
  getPrevSunday(date) {
    while (date.getDay() > 0) {
      date = new Date(date.getTime() - this.oneDay);
    }
    
    return date;
  }
  
  nextWeekdayOfMonth(weekday, date) {

     let idate = new Date(date.getTime());
    while (true) {
      if (idate.getDay() === weekday) {
          break;
      }
      idate.setDate(idate.getDate() + 1);
    }
    return idate;
  }
  
  nthWeekdayOfMonth(weekday, occurrence, date) {
    var countOfOccurrences = 0,

      idate = new Date(date.getTime());
    while (true) {
      if (idate.getDay() === weekday) {
        if (++countOfOccurrences == occurrence) {
          break;
        }
      }
      idate.setDate(idate.getDate() + 1);
    }
    return idate.getDate();
  }

  getFirstSundayInMonth(date) {
    return this.nthWeekdayOfMonth(0, 1, date);
  }

  getDaysInPreviousMonth(month) {
    month--;
    if (month == 0) {
      month = 12;
    }

    return this.getDaysInMonth(month, this.year);
  }

  ngOnInit() {
    
    let today = new Date();
    this.month = today.getMonth() + 1;
    this.year = today.getFullYear();
     
    this.editActivityModes = [
     { label: "Activity", value: "Activity" },
     { label: "Activity Email", value: "Activity Email" },
     ];

    this.otherActivities = [
      {  label: "reservation", value: "reservation" },
      {  label: "commissioning", value: "commissioning" },
      {  label: "beamline start-up", value: "beamline start-up" },
      {  label: "experiment set-up", value: "experiment set-up" },
      {  label: "non-x-ray", value: "non-x-ray" },
      ];
    this.selectedEmailRecipients = [];
    this.editActivityMode = "Activity";
    this.switchMonthView(null);
    this.getDefaultColors();
    this.getSystemActivityTypes();
    let urlRoot = this.authService.getUrlRoot();

     if (urlRoot.indexOf("mis7") >= 0 || urlRoot.indexOf("localhost") >= 0) {
        this.isDev = true;
      } else {
        this.isDev = false;
      }
  }

  getDateForDayInPrevMonth(year, month, day) {
    month -= 1;
    if (month == 0) {
      year -= 1;
      month = 12;
    }

    let date = new Date(year + "/" + month + "/" + day + this.timeZone);
    return date;
  }

  switchMonthView(highlightedActivityId) {
    this.monthView();
    this.updateCalendar(highlightedActivityId);
  }

  switchWeekView() {
    this.weekView();
    this.updateCalendar(null);
  }

  todayView() {
    this.oneDayViewDate = new Date();
    this.switchDayView();
  }
  
  switchDayView() {
    this.dayView();
    this.updateCalendar(null);
  }

  monthView() {
    this.view = "month";
    this.weekStart = null;
    this.columns = [{header: "Sun"},
    {header: "Mon"},
    {header: "Tue"},
    {header: "Wed"},
    {header: "Thu"},
    {header: "Fri"},
    {header: "Sat"}];

    this.weeks = [];
    let firstDayOfMonth = new Date(this.year + "/" + this.month + "/1");
    this.oneDayViewDate = new Date(firstDayOfMonth.getTime() + this.oneDay);
    let firstSunPos: number = this.getFirstSundayInMonth(firstDayOfMonth);

    let week = [];
    let counter = 0;
    let timeAtStart = firstDayOfMonth.getTime();
    let todayDate = new Date();
    let today = new Date((todayDate.getMonth() + 1) + "/" + todayDate.getDate() + "/" + todayDate.getFullYear()).getTime();
    let daysAcross = this.columns.length;

    // end of previous month
    if (firstSunPos > 1) {
      let daysPrevMonth = this.getDaysInPreviousMonth(this.month);
      let dateInPrevMonth = (daysPrevMonth + firstSunPos) - daysAcross;
      timeAtStart = this.getDateForDayInPrevMonth(this.year, this.month, dateInPrevMonth).getTime();

      for (let n = dateInPrevMonth; n <= daysPrevMonth; n++) {
        let newActivityStartTime = timeAtStart + this.oneDay;
        let newActivityStartTimeAsDate = new Date(newActivityStartTime);
        let newActivityDisabled = this.isInPreviousFY(newActivityStartTimeAsDate)
          || !this.isInSelectedRun(newActivityStartTimeAsDate);
        let isToday = today >= timeAtStart && today <= timeAtStart + this.oneDay;
        week.push({day: n, startTime: timeAtStart, 
          background: isToday ? this.todayBackground : "",
          endTime: timeAtStart + this.oneDay,
          newActivityStartTime: newActivityStartTime,
          newActivityDisabled: newActivityDisabled });
        timeAtStart += this.oneDay;
        counter++;
      }
    } else {
        // month starts on a Sunday
            timeAtStart = new Date(this.year + "/" + this.month + "/1" + this.timeZone).getTime();
    }

    // current month
    let day = 1;
    let daysInMonth = this.getDaysInMonth(this.month, this.year);
    for (; day <= daysInMonth;) {

      if (week.length == 7) {
        this.weeks.push(week);
        week = [];
      }

      let newActivityStartTime = timeAtStart + this.oneDay;
      let newActivityStartTimeAsDate = new Date(newActivityStartTime);
      let newActivityDisabled = this.isInPreviousFY(newActivityStartTimeAsDate)
        || !this.isInSelectedRun(newActivityStartTimeAsDate);
      let isToday = today >= timeAtStart && today <= timeAtStart + this.oneDay;

      week.push({day: day, startTime: timeAtStart, 
        background: isToday ? this.todayBackground : "",
        endTime: timeAtStart + this.oneDay,
          newActivityStartTime: newActivityStartTime,
          newActivityDisabled: newActivityDisabled });
      timeAtStart += this.oneDay;
      counter++;
      day++;
    }

    if (day >= daysInMonth && week.length == 7) {
      this.weeks.push(week);
      week = [];
    }
    else {
      // next month
      if (week.length < 7) {
        for (let n = 1; week.length < 7; n++) {
          let newActivityStartTime = timeAtStart + this.oneDay;
          let newActivityStartTimeAsDate = new Date(newActivityStartTime);
          let newActivityDisabled = this.isInPreviousFY(newActivityStartTimeAsDate)
            || !this.isInSelectedRun(newActivityStartTimeAsDate);

          let isToday = today >= timeAtStart && today <= timeAtStart + this.oneDay;

          week.push({day: n, startTime: timeAtStart, 
            background: isToday ? this.todayBackground : "",
            endTime: timeAtStart + this.oneDay,
            newActivityStartTime: newActivityStartTime,
            newActivityDisabled: newActivityDisabled });
            timeAtStart += this.oneDay;
        }
        this.weeks.push(week);
      }
    }
  }


  dayView() {
    this.view = "day";
    
    this.weeks = [];
    this.columns = [{ header: this.getDayName(this.oneDayViewDate.getDay())}];

    let week = [];
    let timeAtStart = this.oneDayViewDate.getTime() - this.oneDay;
    let newActivityStartTime = timeAtStart + this.oneDay;

    let workingDate = new Date(timeAtStart + this.oneDay);
    let day = workingDate.getDate();
    this.month = workingDate.getMonth() + 1;
    this.year = workingDate.getFullYear();
      week.push({day: day, 
          startTime: timeAtStart, 
          endTime: timeAtStart + this.oneDay,
          newActivityStartTime: newActivityStartTime});
      this.weeks.push(week);
  }
  
  getWeekStart(date) {
    return this.getPrevSunday(date);
  }
  
  getFirstDayInMonth() {
    return new Date(this.year + "/" + this.month + "/1");
  }
  
  weekView() {
    
    let comingFromDayView = this.view == "day";
    let comingFromWeekView = this.view == "week";
    
    if (this.view == "month") {
    if (!this.weekStart) {
      let firstDayOfMonth = new Date(this.year + "/" + this.month + "/1");
      let firstSun = new Date(this.year + "/" + this.month + "/" + this.getFirstSundayInMonth(firstDayOfMonth) + this.timeZone);
      this.weekStart = new Date(firstSun.getTime() - 7 * this.oneDay);
    }
    } else if( this.view == "day") {
      let dayViewDate = this.oneDayViewDate; // new Date(this.oneDayViewDate.getFullYear() + "/" + (this.oneDayViewDate.getMonth() + 1) + "/" + this.oneDayViewDate.getDate() + this.timeZone);
      //dayViewDate.setDate(dayViewDate.getDate() + 1);
      let firstSun = this.getPrevSunday(dayViewDate);
      firstSun = new Date(firstSun.getTime() - this.oneDay);
      //if (firstSun.getDay == 0) {
        this.weekStart = firstSun;
      //} else {
      //  this.weekStart = new Date(firstSun.getTime() - this.oneDay); // new Date(firstSun.getTime() - 7 * this.oneDay);
      //}
      /*
      if (this.oneDayViewDate.getDay() == 6) {
        this.weekStart = this.oneDayViewDate;
      } else {
        let firstSun = this.getPrevSunday(new Date(this.oneDayViewDate.getTime() + this.oneDay));
        this.weekStart = firstSun;
      } */
    }
    
    this.oneDayViewDate = this.weekStart;
    this.columns = [{header: "Sun"},
    {header: "Mon"},
    {header: "Tue"},
    {header: "Wed"},
    {header: "Thu"},
    {header: "Fri"},
    {header: "Sat"}];
    this.view = "week";
    this.weeks = [];

    let week = [];
    let todayDate = new Date();
    let today = new Date((todayDate.getMonth() + 1) + "/" + todayDate.getDate() + "/" + todayDate.getFullYear()).getTime();
    
    let timeAtStart = this.weekStart.getTime();
    let workingDate = new Date(timeAtStart + this.oneDay);
    this.month = workingDate.getMonth() + 1;
    this.year = workingDate.getFullYear();
    
    for (let n = 0; n < 7; n++) {
      let day = workingDate.getDate();
      let newActivityStartTime = timeAtStart + this.oneDay;
      let isToday = today >= timeAtStart && today <= timeAtStart + this.oneDay;

      week.push({day: day, 
        background: isToday ? this.todayBackground : "",
        startTime: timeAtStart, 
        endTime: timeAtStart + this.oneDay,
        newActivityStartTime: newActivityStartTime});
      timeAtStart = timeAtStart + this.oneDay;
      workingDate = new Date(workingDate.getTime() + this.oneDay);
    }
    this.weeks.push(week);
    this.view = "week";
    
  }

  eventsOverlap(e1, e2) {
    if (e1.start == e2.start && e1.end == e2.end && e1.overlap == e2.overlap) {
      return true;
    } else {
    return (e1.start > e2.start && e1.start < e2.end
      || e1.end > e2.start && e1.end < e2.end)
      && e1.overlap == e2.overlap;
    }
  }
  
  firstEventIsEarlier(event1, event2) {
    return event1.startAsDateObject.getTime() < event2.startAsDateObject.getTime()
  }
  highlightActivity(highlightedActivityId) {
    let event = this.events.find( e => { return e.eventFromApi.Activity_ID == highlightedActivityId });
    event.border = "5px solid red";
    this.month = event.startAsDateObject.getMonth() + 1;
    this.switchMonthView(highlightedActivityId);
    this.getScheduleGuidance.emit(event.eventFromApi.BTR);
  }

  sortOverlappingEvents() {

    for (let i = 0; i <= 1; i++) {
      for (let n = 0; n < this.events.length; n++) {
        let outer = this.events[n];
        for (let m = 0; m < this.events.length; m++) {
          let eventsOverlap = false;
          let inner = this.events[m];
          if (this.eventsOverlap(outer, inner) && outer.eventFromApi.Activity_ID != inner.eventFromApi.Activity_ID) {
            eventsOverlap = true;
          }
          
          if (eventsOverlap) {
            if (outer.eventFromApi.Category == "System Activity") {
              outer.overlap = 0;
              inner.overlap = 1;
            } else {
              if (this.firstEventIsEarlier(outer, inner)) {
                inner.overlap = inner.overlap + 1;
              } else {
                outer.overlap = outer.overlap + 1;
              }
            }
          }
        }
      }
    }
  }
  
  changeEventTimesToStackShortEvents() {
    let offsetMillis = 0;
    this.events.forEach(event => {
      let hours = (event.end - event.start) / this.oneHour;
      
      if (hours < 24) {
        offsetMillis++; // hack
        let startOfDay = event.start - (event.start % this.oneDay) - offsetMillis;
        event.start = startOfDay + this.oneHour * 2;
        event.end = startOfDay + this.oneHour * 22;
      }
    });
  }

  
  updateCalendar(highlightedActivityId) {
    this.events.forEach(event => {
      event.startDate = event.startDate.replace("T", " ");
      event.start = new Date(event.startDate + "+00:00").getTime();

      event.endDate = event.endDate.replace("T", " ");
      event.end = new Date(event.endDate + "+00:00").getTime();

      event.color = event.eventFromApi.Hex_Display_Color;
      if (event.color == "FFFFFF") {
        event.border = "1px solid black";
      }
      event.overlap = 0;
            
      let exampleDate = new Date(event.start);
      let timezoneOffsetMillis = exampleDate.getTimezoneOffset() * 60 * 1000;
      event.startAsDateObject= new Date(event.start + timezoneOffsetMillis);// new Date(this.eventBeingShown.start + timezoneOffsetMillis ); // = new Date(this.eventBeingShown.start);
      event.endAsDateObject = new Date(event.end + timezoneOffsetMillis);

    });
    this.changeEventTimesToStackShortEvents();
    this.sortOverlappingEvents();
    //this.makeSureEventsShowEarliestFirst();
    //this.sortOverlappingEvents();
    
    let daysAcross = this.columns.length;
    let widthOfADay = this.width / daysAcross;
    let self = this;
      this.weeks.forEach(week => {
      let dayCounter = 1;
      week.forEach(day => {

        day['events'] = [];
        self.events.forEach(event => {
          // event starts or ends within day
          if (event.start >= day.startTime && event.start <= day.endTime
            || event.end > day.startTime && event.end <= day.endTime
            || event.start < day.startTime && event.end > day.endTime) {
              let eventOnCalendar = {};
              let eventShouldAppear = false;
              // if event starts in that day
            if (event.start >= day.startTime && event.start < day.endTime) {
              eventOnCalendar['leftMargin'] = ((event.start % this.oneDay) / this.oneDay) * widthOfADay;
              let end = Math.min(day.endTime, event.end);
              eventOnCalendar['width'] = ((end - event.start) / this.oneDay) * widthOfADay;
              let totalWidthOfEntireEvent = ((event.end - event.start) / this.oneDay) * widthOfADay;
              let widthInThisRow = (daysAcross - dayCounter) * widthOfADay + eventOnCalendar['width'];
              let widthShownInThisRow = Math.min(totalWidthOfEntireEvent, widthInThisRow);
              eventOnCalendar['widthInThisRow'] = widthShownInThisRow;
              eventShouldAppear = true;
            } 
            // it's a Sunday and event continues beyond that day
            else if (dayCounter == 1 && event.end >= day.startTime) {
              let eventWidthInPx = ((event.end - day.startTime) / this.oneDay) * widthOfADay;
              let widthOfThisRowInPx = daysAcross * widthOfADay;
              
              let maxWidthInThisRow = Math.min(eventWidthInPx, widthOfThisRowInPx);
              eventOnCalendar['widthInThisRow'] = maxWidthInThisRow;
              eventShouldAppear = true;
            }
            
            if (eventShouldAppear) {
              eventOnCalendar['overlap'] = event.overlap;
              eventOnCalendar['title'] =  event.eventFromApi.DisplayTitle;
              eventOnCalendar['category'] =  event.eventFromApi.Category;
              eventOnCalendar['activityId'] =  event.eventFromApi.Activity_ID;
              eventOnCalendar['comment'] =  event.eventFromApi.Activity_Comment;
              eventOnCalendar['start'] =  event.start;
              eventOnCalendar['color'] = event.color;
              eventOnCalendar['border'] = event.border;
              eventOnCalendar['tooltip'] = this.formatFullStartAndEndDates(event)
                                            + "\n" + event.eventFromApi.DisplayTitle
              eventOnCalendar['event'] = event;
              if (eventOnCalendar['category'] == "Beamtime Request") {
                eventOnCalendar['emailHold'] = "Emails on hold: " + 
                  (event.eventFromApi.HoldEmail ? "YES" : "NO");
              }
              day.events.push(eventOnCalendar);
            }
          }
        })
        dayCounter++;
      })
    });
  }
 
  setEndDateDefault($event) {
      this.eventBeingShown.endDateAsObject = 
        new Date($event.getTime() + this.oneHour);
  }
  
  showActivity(event) {
    this.eventBeingShown = event;
    this.initialShiftsInActivity = this.calcShifts(); 
    this.checkUserActivityEditPrivilege();
    this.getScheduleGuidance.emit(event.eventFromApi.BTR);
    console.log(event.eventFromApi);
    if (event.eventFromApi.Category != "System Activity") {
      this.populateBeamlineRequests();
      this.getActivityItemVisibilityPolicy();
      this.viewingActivity = true;
    } else {
      this.viewingRunScheduleActivity = true;
    }
  }
  
  hideRunScheduleActivityDialog() {
    this.viewingRunScheduleActivity = false;
  }

  editRunScheduleActivity() {
    console.log(this.eventBeingShown);
    this.hideRunScheduleActivityDialog();

    if (this.eventBeingShown.eventFromApi.Activity_Type) {
      this.getSystemResources(this.eventBeingShown.eventFromApi.Activity_Type);
    }

    if (this.eventBeingShown.start) {
      let exampleDate = new Date(this.eventBeingShown.start);
      let timezoneOffsetMillis = exampleDate.getTimezoneOffset() * 60 * 1000;
    }
    //this.eventBeingShown.eventFromApi.Activity_Type = "";
    this.editingRunScheduleActivity = true;
  }

  cancelEditingRunScheduleActivity() {
    this.editingRunScheduleActivity = false;
    this.refreshFromDatabase.emit();
    this.getScheduleGuidance.emit();
  }

  validateEmail(email) 
  {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

  hideViewActivityDialog() {
    this.viewingActivity = false;
    this.getScheduleGuidance.emit(null);
  }
  
  randomChannel(brightness){
    let r = 255-brightness;
    let n = 0|((Math.random() * r) + brightness);
    let s = n.toString(16);
    return (s.length==1) ? '0'+s : s;
  }
     
  randomColor(brightness){

    return '#' + this.randomChannel(brightness) + this.randomChannel(brightness) + this.randomChannel(brightness);
  }

  getEventDesc(event) {
    let date = new Date(event.startDate);
    let hours = date.getHours().toString();
    if (date.getHours() < 10) {
      hours = "0" + hours;
    }
    let mins = date.getMinutes().toString();
    if (date.getMinutes() < 10) {
      mins = "0" + mins;
    }
    let fd = hours + ":" + mins + " " + event.title;
    return fd;
  }

  formatFullStartAndEndDates(event) {
    return this.formatFullDate(new Date(event.startDate))
      + " - "
      + this.formatFullDate(new Date(event.endDate))
      + " \n" + event.title;
  }

  formatFullDate(date) {
    let hours = date.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }

    let mins = date.getMinutes();

    if (mins < 10) {
      mins = "0" + mins;
    }

    let fd = this.getDayName(date.getDay())
      + ", " + this.getMonthName(date.getMonth() + 1)
      + " " + date.getDate()
      + ", " + date.getFullYear()
      + " " + hours + ":" + mins;
    return fd;
  }

  formatMinimalDateFromTime(time: number) {
    return this.formatMinimalDate(new Date(time));
  }

  formatMinimalDate(date: Date) {
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
  }

  getDaysInMonth(month, year) {
    let days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    days[2] = year % 4 == 0 ? 29 : 28;
    return days[month];
  }

  getDayName(day) {
    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[day % 7];
  }

  next() {

    if (this.view == "month") {
      this.nextMonth();
    } else if (this.view == "week") {
      this.nextWeek();
    } else if (this.view == "day") {
      this.nextDay();
    }
  }

  previous() {
    if (this.view == "month") {
      this.previousMonth();
    } else if (this.view == "week") {
      this.previousWeek();
    } else if (this.view == "day") {
      this.previousDay();
    }
  }

  previousDay() {
    this.oneDayViewDate = new Date(this.oneDayViewDate.getTime() - this.oneDay);
    this.month = this.oneDayViewDate.getMonth() + 1;
    this.year = this.oneDayViewDate.getFullYear();
    this.switchDayView();
  }

  nextDay() {
    this.oneDayViewDate = new Date(this.oneDayViewDate.getTime() + this.oneDay);
    this.month = this.oneDayViewDate.getMonth() + 1;
    this.year = this.oneDayViewDate.getFullYear();
    this.switchDayView();
  }

  nextWeek() {
    this.weekStart = new Date(this.weekStart.getTime() + 7 * this.oneDay);
    this.switchWeekView();
  }

  previousWeek() {
    this.weekStart = new Date(this.weekStart.getTime() - 7 * this.oneDay);
    this.switchWeekView();
  }

  previousMonth() {
    this.month = this.month - 1;
    if (this.month == 0) {
      this.month = 12;
      this.year -= 1;
    }
    this.switchMonthView(null);
  }

  nextMonth() {
    this.month = this.month + 1;
    if (this.month == 13) {
      this.month = 1;
      this.year += 1;
    }
    this.switchMonthView(null);
  }

  getMonthName(month) {
    let names = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return names[month];
  }
}
