import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpResponse, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';
import { OverviewService } from './overview/overview.service';
import { AdminService } from './admin/admin.service';
import { ViewEditComponent } from './viewedit/viewedit.component';
import { ViewAllComponent } from './viewall/viewall.component';
import { CalendarComponent } from './calendar/calendar.component';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/primeng';
import {ButtonModule} from 'primeng/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MenubarModule } from 'primeng/primeng';
import { AuthService } from './auth/auth.service';
import { UserDetailsService } from './login/user-details.service';
import { LoginComponent } from './login/login.component';
import {InputTextModule} from 'primeng/inputtext';
import {TabMenuModule} from 'primeng/tabmenu';
import {PanelModule} from 'primeng/panel';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import { ViewEditService } from './viewedit/viewedit.service';
import { CalendarService } from './calendar/calendar.service';
import { CreateScheduleComponent } from './createschedule/createschedule.component';
import { CreateScheduleService } from './createschedule/createschedule.service';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {TreeNode} from 'primeng/api';
import {CheckboxModule} from 'primeng/checkbox';
import {ColorPickerModule} from 'primeng/colorpicker';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ListboxModule} from 'primeng/listbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { AdminComponent } from './admin/admin.component';
import { AppService } from './app.service';
import { GrowlModule } from 'primeng/primeng';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';
import { TabViewModule } from 'primeng/tabview';
import { SettingsAuthorizationComponent } from './settings-authorization/settings-authorization.component';
import { SettingsAuthorizationService } from './settings-authorization/settings-authorization.service';
import { SettingsTemplatesComponent } from './settings-templates/settings-templates.component';
import { SettingsTemplatesService } from './settings-templates/settings-templates.service';
import { PreferencesComponent } from './settings-preferences/preferences.component';
import { PreferencesService } from './settings-preferences/preferences.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReportingTabComponent } from './reporting-tab/reporting-tab.component';
import { ReportingRunScheduleComponent } from './reporting-run-schedule/reporting-run-schedule.component';
import { ReportingRunScheduleService } from './reporting-run-schedule/reporting-run-schedule.service';
import { FileSaverService } from './shared/filesaver';
import { ReportingBeamtimeUsageComponent } from './reporting-beamtime-usage/reporting-beamtime-usage.component';
import { ReportingBeamtimeUsageService } from './reporting-beamtime-usage/reporting-beamtime-usage.service';
import { ReportingBeamlineScheduleComponent } from './reporting-beamline-schedule/reporting-beamline-schedule.component';
import { ReportingBeamlineScheduleService } from './reporting-beamline-schedule/reporting-beamline-schedule.service';
import { ReportingBeamlineScheduleAuditComponent } from './reporting-beamline-schedule-audit/reporting-beamline-schedule-audit.component';
import { ReportingBeamlineScheduleAuditService } from './reporting-beamline-schedule-audit/reporting-beamline-schedule-audit.service';
import { ViewAllService } from './viewall/viewall.service';
import {MultiSelectModule} from 'primeng/multiselect';
import { PrintDirective } from './shared/print.directive';
import {NgxPrintModule} from 'ngx-print';
import { DragulaModule, DragulaService } from 'ng2-dragula';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    LoginComponent,
    ViewEditComponent,
    CalendarComponent,
    ViewAllComponent,
    AdminComponent,
    CreateScheduleComponent,
    SettingsAuthorizationComponent,
    SettingsTabComponent,
    SettingsTemplatesComponent,
    PreferencesComponent,
    ReportingTabComponent,
    ReportingRunScheduleComponent,
    ReportingBeamtimeUsageComponent,
    ReportingBeamlineScheduleComponent,
    ReportingBeamlineScheduleAuditComponent,
    PrintDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    MenubarModule,
    HttpClientModule,
    MessagesModule,
    ButtonModule,
    InputTextModule,
    TabMenuModule,
    PanelModule,
    TableModule,
    TooltipModule,
    OverlayPanelModule,
    DialogModule,
    CalendarModule,
    TreeModule,
    TreeTableModule,
    CheckboxModule,
    ColorPickerModule,
    AutoCompleteModule,
    SelectButtonModule,
    ListboxModule,
    ConfirmDialogModule,
    GrowlModule,
    TabViewModule,
    RadioButtonModule,
    MultiSelectModule,
    NgxPrintModule,
    DragulaModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthService, 
    UserDetailsService,
    ViewEditService,
    CalendarService,
    ConfirmationService,
    OverviewService,
    AdminService,
    AppService,
    CreateScheduleService,
    SettingsAuthorizationService,
    SettingsTemplatesService,
    PreferencesService,
    ReportingRunScheduleService,
    FileSaverService,
    ReportingBeamtimeUsageService,
    ReportingBeamlineScheduleService,
    ReportingBeamlineScheduleAuditService,
    ViewAllService,
    DragulaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }