import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'settings-tab',
  templateUrl: './settings-tab.component.html'
})
  
export class SettingsTabComponent {
  
}
